import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  SimpleArticle,
  StrapiHotArticlesComponent,
  StrapiImageClass,
} from '@nursing/pwn-cms-model/lib';
import { ArticleService } from '@shared/service/article/article.service';
import { ConfigurationService } from '@shared/service/configuration/configuration.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-hot-article-list',
  templateUrl: './hot-article-list.component.html',
  styleUrls: [
    './hot-article-list.component.scss',
    './hot-article-list.component.mobile.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotArticleListComponent
  implements OnInit, OnDestroy, AfterViewInit {
  readonly tenantId = environment.tenantId;
  @Input()
  component: StrapiHotArticlesComponent;

  subscription: Subscription;
  articleList: SimpleArticle[] = [];

  backgroundImage: string;
  viewLoaded = false;

  constructor(
    public articleService: ArticleService,
    private configurationService: ConfigurationService,
    private cd: ChangeDetectorRef
  ) {}
  ngAfterViewInit(): void {
    // unset min height for cls optimization
    setTimeout(() => {
      this.viewLoaded = true;
    }, 2000);
  }

  ngOnInit(): void {
    this.configurationService.getConfiguration().subscribe((config) => {
      if (config && config.backgroundImage) {
        this.backgroundImage = new StrapiImageClass(
          config.backgroundImage
        ).getPublicImageUrl(
          this.getServerUrl() + '/strapi-proxy/' + this.tenantId
        );
        this.cd.markForCheck();
      }
    });
    const tag = this.component?.tag;

    if (tag) {
      this.subscription = this.articleService
        .getArticleListByTag(tag.name)
        .subscribe((list) => {
          this.articleList = list;
          this.cd.markForCheck();
        });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getServerUrl(): string {
    return environment.serverUrl;
  }
}
