import { Component, Input } from '@angular/core';
import { StrapiAttachmentListComponent } from '@nursing/pwn-cms-model/lib';

@Component({
  selector: 'app-article-attachment-list',
  templateUrl: './article-attachment-list.component.html',
  styleUrls: ['./article-attachment-list.component.scss'],
})
export class ArticleAttachmentListComponent {
  @Input()
  attachments: StrapiAttachmentListComponent;

  constructor() {}
}
