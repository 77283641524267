import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Category,
  ElkDocumentObject,
  StrapiCategory,
  StrapiImage,
  StrapiImageClass,
  UrlUtils,
} from '@nursing/pwn-cms-model/lib';
import { SearchBarResult } from '@shared/model/search-bar/search-bar-result';
import { SearchBarInputData } from '@shared/model/search-bar/search-bar-input-data';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CategoryService } from '@shared/service/category/category.service';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from '@shared/service/authentication/authentication.service';
import { UserProductService } from '@shared/service/user-product/user-product.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-article-grid',
  templateUrl: './article-grid.component.html',
  styleUrls: [
    './article-grid.component.scss',
    './article-grid.component.mobile.scss',
  ],
})
export class ArticleGridComponent implements OnInit, OnDestroy {
  readonly tenantId = environment.tenantId;

  @Input() set searchData(val: SearchBarInputData) {
    if (val) {
      this._searchData = val;
    }
  }

  @Input()
  searchType: string;

  _searchData: SearchBarInputData;
  userFavoriteArticlesIds: string[] = [];
  totalPages: number;
  itemsPerPage: number;
  totalElements: number;
  page: number | undefined;
  prevPage = 1;
  categoryImg: StrapiImage;

  articles: ElkDocumentObject[];
  UrlUtils = UrlUtils;

  slugIdMap = new Map();

  categoriesColors: Map<string, string> = new Map<string, string>();

  categoriesSub: Subscription;
  userFavoriteArticleIds$: Subscription;
  category$: Observable<StrapiCategory>;
  categorySub: Subscription;

  isLoggedIn$: Observable<boolean>;
  constructor(
    private _sanitizer: DomSanitizer,
    private categoryService: CategoryService,
    private el: ElementRef,
    private authenticationService: AuthenticationService,
    private userProductService: UserProductService
  ) {}

  ngOnInit(): void {
    this.categoriesSub = this.categoryService
      .getCategories()
      .subscribe((res: Category[]) => {
        res.forEach((category: Category) => {
          this.categoriesColors.set(category.id, category.color);
          this.slugIdMap.set(
            category.id,
            this.categoryService.getSlugId(category)
          );
        });
      });
    this.isLoggedIn$ = this.authenticationService.isLoggedIn$;

    this.userFavoriteArticleIds$ = this.userProductService._userFavoriteArticlesIds.subscribe(
      (value) => {
        this.userFavoriteArticlesIds = value;
      }
    );
  }

  ngOnDestroy(): void {
    this.categoriesSub?.unsubscribe();
    this.categorySub?.unsubscribe();
  }

  changePage(event: any, pageReset?: boolean): void {
    this.scrollToFirstInvalidControl();

    if (pageReset === true) {
      this.prevPage = event;
      this.page = this.prevPage === 1 ? undefined : this.prevPage;
      return;
    }
    if (event === this.prevPage) {
      return;
    }
    this.page = this.prevPage = event;
  }

  scrollToFirstInvalidControl() {
    setTimeout(() => {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
        'app-search-bar'
      );

      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
      });
    });
  }

  getTopOffset(controlEl: HTMLElement): number {
    const offset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - offset;
  }

  getLeadHtml(article: ElkDocumentObject): SafeHtml | undefined {
    if (article.description != null) {
      return this._sanitizer.bypassSecurityTrustHtml(article.description);
    } else {
      return null;
    }
  }

  parseSearchResult(result: SearchBarResult): void {
    this.articles = result.data;
    this.totalElements = result.totalElements;
    this.totalPages = result.totalPages;
    this.itemsPerPage = result.itemsPerPage;
  }
}
