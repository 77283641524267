import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
} from '@angular/core';
import { StrapiImage, StrapiImageSizeEnum } from '@nursing/pwn-cms-model/lib';
import { UrlService } from '@shared/service/url/url.service';

@Directive({
  selector: '[strapiImage]',
})
export class StrapiImageDirective implements OnChanges {
  @Input() strapiImage: StrapiImage;
  @Input() strapiImageDesiredFormat: StrapiImageSizeEnum | undefined;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private urlService: UrlService
  ) {}

  ngOnChanges(): void {
    const defaultAlt = this.el.nativeElement.getAttribute('alt');
    if (this.strapiImage) {
      this.renderer.setAttribute(
        this.el.nativeElement,
        'src',
        this.getImageUrl()
      );
      this.renderer.setAttribute(
        this.el.nativeElement,
        'alt',
        this.strapiImage.alternativeText?.length > 0
          ? this.strapiImage.alternativeText
          : defaultAlt
      );
    }
  }

  private getImageUrl(): string {
    const { url } = this.strapiImage;

    if (this.strapiImageDesiredFormat) {
      const [
        modifiedImageurl,
        originalImageUrl,
      ] = this.urlService.getImageUrlWithSize(
        url,
        this.strapiImageDesiredFormat
      );
      if (!this.strapiImage.formats) {
        return originalImageUrl;
      }
      if (this.strapiImage.formats[this.strapiImageDesiredFormat]) {
        return modifiedImageurl;
      }
      return originalImageUrl;
    }

    return this.urlService.getImageUrl(url);
  }
}
