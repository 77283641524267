import { Component, Input } from '@angular/core';
import { StrapiPartner, StrapiImageSizeEnum } from '@nursing/pwn-cms-model/lib';
import { environment } from '@env/environment';

@Component({
  selector: 'app-partner-logo',
  templateUrl: './partner-logo.component.html',
  styleUrls: [
    './partner-logo.component.scss',
    './partner-logo.component.mobile.scss',
  ],
})
export class PartnerLogoComponent {
  readonly tenantId = environment.tenantId;
  @Input()
  partner: StrapiPartner;

  public imageFormat = StrapiImageSizeEnum.thumbnail;

  constructor() {}
}
