<app-search-bar
  [isGridView]="true"
  (resetPage)="changePage($event, true)"
  [data]="_searchData"
  (searchBarResult)="parseSearchResult($event)"
  [pageChanged]="page"
  [searchType]="searchType"
></app-search-bar>

<hr />
<div class="articles-grid">
  <div class="article-grid" *ngFor="let article of articles">
    <a
      [routerLink]="['/artykul', UrlUtils.toSlugId(article.title, article.id)]"
    >
      <div class="article-grid-image">
        <img
          *ngIf="article.secondImage"
          [strapiImage]="article.secondImage"
          [alt]="article?.title"
          loading="lazy"
        />
        <img
          *ngIf="!article.secondImage && article.image"
          [strapiImage]="article.image"
          [alt]="article?.title"
          loading="lazy"
        />
      </div>
    </a>
    <a
      [routerLink]="['/artykul', UrlUtils.toSlugId(article.title, article.id)]"
    >
      <p class="article-grid-title">{{ article.title }}</p>
    </a>
    <a
      class="article-grid-link"
      [routerLink]="['/artykul', UrlUtils.toSlugId(article.title, article.id)]"
    >
      <span>Sprawdź</span>
      <span class="material-icons arrow-icon">trending_flat</span></a
    >
    <div class="spacer"></div>
  </div>
</div>
<hr *ngIf="articles && articles.length" class="last-article" />
<div *ngIf="!articles" class="article-container nothing-found-container">
  <div class="search-result-loading-placeholder"></div>
</div>
<div *ngIf="articles && articles.length === 0" class="nothing-found-container">
  <p class="title">
    Niestety, nie udało nam się niczego znaleźć
  </p>
  <p class="lead gray-lead">Sprawdź pisownię, uogólnij szukaną frazę</p>
</div>
<ngb-pagination
  class="desktop-pagination"
  [page]="page || 1"
  [pageSize]="itemsPerPage"
  [collectionSize]="totalElements"
  [rotate]="true"
  [boundaryLinks]="true"
  [maxSize]="3"
  [ngClass]="{
    'hidden-previous-button': page === 1 || page === undefined,
    'hidden-next-button': page === this.totalPages || this.totalPages === 1
  }"
  (pageChange)="changePage($event)"
  *ngIf="articles?.length > 0"
>
  <ng-template ngbPaginationPrevious>poprzednia</ng-template>
  <ng-template ngbPaginationNext>następna</ng-template>
</ngb-pagination>

<ngb-pagination
  class="mobile-pagination"
  [page]="page || 1"
  [pageSize]="itemsPerPage"
  [collectionSize]="totalElements"
  [rotate]="true"
  [directionLinks]="false"
  [maxSize]="3"
  [ngClass]="{
    'hidden-previous-button': page === 1 || page === undefined,
    'hidden-next-button': page === this.totalPages || this.totalPages === 1
  }"
  (pageChange)="changePage($event)"
  *ngIf="articles?.length > 0"
>
</ngb-pagination>
