export class GalleryImage {
  url!: string;
  title?: string;
  description?: string;
  link?: string;

  constructor(
    url: string,
    title?: string,
    description?: string,
    link?: string
  ) {
    this.url = url;
    this.title = title;
    this.description = description;
    this.link = link;
  }
}
