import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { MemoryStorage } from './memorystorage.service';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService implements Storage {
  [name: string]: any;
  private readonly localStorage: Storage;

  readonly length: number;
  constructor(private _platform: Platform) {
    if (this._platform.isBrowser && window?.localStorage) {
      this.localStorage = window.localStorage;
    } else {
      this.localStorage = new MemoryStorage();
    }
  }

  clear(): void {
    this.localStorage.clear();
  }

  getItem(key: string): string | null {
    return this.localStorage.getItem(key);
  }

  key(index: number): string | null {
    return this.localStorage.key(index);
  }

  removeItem(key: string): void {
    this.localStorage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    this.localStorage.setItem(key, value);
  }
}
