import { Component, Input } from '@angular/core';
import {
  SimpleCategory,
  StrapiAdComponent,
  StrapiBookstoreComponent,
  StrapiComponentTypeEnum,
  StrapiNewsletterComponent,
  StrapiPartnersComponent,
  StrapiSocialMediaComponent,
} from '@nursing/pwn-cms-model/lib';

@Component({
  selector: 'app-article-bottom-content',
  templateUrl: './article-bottom-content.component.html',
  styleUrls: ['./article-bottom-content.component.scss'],
})
export class ArticleBottomContentComponent {
  _components: (
    | StrapiPartnersComponent
    | StrapiAdComponent
    | StrapiNewsletterComponent
    | StrapiBookstoreComponent
    | StrapiSocialMediaComponent
  )[];

  componentTypes: typeof StrapiComponentTypeEnum = StrapiComponentTypeEnum;

  @Input()
  set components(
    components: (
      | StrapiAdComponent
      | StrapiNewsletterComponent
      | StrapiPartnersComponent
      | StrapiBookstoreComponent
      | StrapiSocialMediaComponent
    )[]
  ) {
    this._components = components;
  }

  @Input()
  category: SimpleCategory;

  @Input()
  categories: SimpleCategory[];

  constructor() {}
}
