<div class="content-container">
  <a [href]="fbShareUrl" target="_blank" rel="noopener noreferrer">
    <button class="facebook-button" *ngIf="component?.facebookShare">
      <span>Udostępnij</span>
      <i class="fab fa-facebook"></i>
    </button>
  </a>
  <div class="spacer" *ngIf="component?.twitterShare"></div>
  <a [href]="twShareUrl" target="_blank" rel="noopener noreferrer">
    <button class="twitter-button" *ngIf="component?.twitterShare">
      <span class="margin-span-twitter">Udostępnij</span>
      <img
        class="icon-twitter"
        src="assets/icons/twitter.svg"
        alt="Ikona Twittera"
      />
    </button>
  </a>
  <div class="spacer" *ngIf="component?.linkedInShare"></div>
  <a [href]="liShareUrl" target="_blank" rel="noopener noreferrer">
    <button class="linkedin-button" *ngIf="component?.linkedInShare">
      <span>Udostępnij</span>
      <i class="fab fa-linkedin-in"></i>
    </button>
  </a>

  <!--
  <div class="spacer"></div>
 <button id="favourites-button">
    <span>Dodaj do ulubionych</span>
    <i class="material-icons">favorite_border</i>
  </button>
  -->
</div>
