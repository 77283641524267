import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  StrapiConfiguration,
  StrapiAuthenticationConfiguration,
  StrapiTheme,
  StrapiNewsletterConfiguration,
} from '@nursing/pwn-cms-model/lib';
import { environment } from '@env/environment';
import { TransferState, makeStateKey } from '@angular/platform-browser';

const CONFIGURATION_KEY = makeStateKey<StrapiConfiguration>(
  'configurationService_configuration'
);
const AUTH_CONFIGURATION_KEY = makeStateKey<StrapiAuthenticationConfiguration>(
  'configurationService_auth'
);

const NEWSLETTER_CONFIGURATION_KEY = makeStateKey<
  StrapiNewsletterConfiguration
>('configurationService_newsletter');

const THEME_CONFIGURATION_KEY = makeStateKey<StrapiAuthenticationConfiguration>(
  'configurationService_theme'
);

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  configSubject = new BehaviorSubject(null);
  configInitialized = false;

  authConfigSubject = new BehaviorSubject(null);
  authConfigInitialized = false;

  themeSubject = new BehaviorSubject(null);
  themeInitialized = false;

  newsletterSubject = new BehaviorSubject(null);
  newsletterInitialized = false;

  private readonly tenantId = environment.tenantId;

  constructor(private http: HttpClient, private state: TransferState) {}

  getConfiguration(): Observable<StrapiConfiguration> {
    if (!this.configInitialized) {
      this.loadConfiguration();
      this.configInitialized = true;
    }
    return this.configSubject;
  }

  getAuthenticationConfiguration(): Observable<
    StrapiAuthenticationConfiguration
  > {
    if (!this.authConfigInitialized) {
      this.loadAuthenticationConfiguration();
      this.authConfigInitialized = true;
    }
    return this.authConfigSubject;
  }

  getTheme(): Observable<StrapiTheme> {
    if (!this.themeInitialized) {
      this.loadTheme();
      this.themeInitialized = true;
    }
    return this.themeSubject;
  }

  getNewsletter(): Observable<StrapiNewsletterConfiguration> {
    if (!this.newsletterInitialized) {
      this.loadNewsletterConfiguration();
      this.newsletterInitialized = true;
    }
    return this.newsletterSubject;
  }

  loadConfiguration() {
    const config = this.state.get<StrapiConfiguration>(
      CONFIGURATION_KEY,
      null as StrapiConfiguration
    );

    if (!config) {
      this.http
        .get<StrapiConfiguration>(
          `/strapi-proxy/${this.tenantId}/configuration/configuration`
        )
        .subscribe((conf) => {
          this.configSubject.next(conf);
          this.state.set<StrapiConfiguration>(
            CONFIGURATION_KEY,
            conf as StrapiConfiguration
          );
        });
    } else {
      this.configSubject.next(config);
    }
  }

  loadAuthenticationConfiguration() {
    const config = this.state.get<StrapiAuthenticationConfiguration>(
      AUTH_CONFIGURATION_KEY,
      null as StrapiAuthenticationConfiguration
    );
    if (!config) {
      this.http
        .get<StrapiAuthenticationConfiguration>(
          `/strapi-proxy/${this.tenantId}/configuration/authentication`
        )
        .subscribe((conf) => {
          this.authConfigSubject.next(conf);
          this.state.set<StrapiAuthenticationConfiguration>(
            AUTH_CONFIGURATION_KEY,
            conf
          );
        });
    } else {
      this.authConfigSubject.next(config);
    }
  }

  loadTheme() {
    const config = this.state.get<StrapiAuthenticationConfiguration>(
      THEME_CONFIGURATION_KEY,
      null
    );
    if (!config) {
      this.http
        .get<StrapiAuthenticationConfiguration>(
          `/strapi-proxy/${this.tenantId}/configuration/theme`
        )
        .subscribe((conf) => {
          this.themeSubject.next(conf);
          this.state.set<StrapiAuthenticationConfiguration>(
            THEME_CONFIGURATION_KEY,
            conf
          );
        });
    } else {
      this.themeSubject.next(config);
    }
  }

  loadNewsletterConfiguration() {
    const config = this.state.get<StrapiNewsletterConfiguration>(
      NEWSLETTER_CONFIGURATION_KEY,
      null
    );
    if (!config) {
      this.http
        .get<StrapiNewsletterConfiguration>(
          `/strapi-proxy/${this.tenantId}/configuration/newsletter`
        )
        .subscribe((conf) => {
          this.newsletterSubject.next(conf);
          this.state.set<StrapiNewsletterConfiguration>(
            NEWSLETTER_CONFIGURATION_KEY,
            conf
          );
        });
    } else {
      this.newsletterSubject.next(config);
    }
  }
}
