import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Article, SimpleArticle } from '@nursing/pwn-cms-model/lib';
import { map } from 'rxjs/operators';
import { LocalstorageService } from '@shared/service/ssr/localstorage.service';
import { environment } from '@env/environment';
import { TransferState, makeStateKey } from '@angular/platform-browser';

const redirectArticleIdKey = 'redirectArticleId';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  private readonly tenantId = environment.tenantId;
  albtTimeout = false;
  gtnTimeout = false;
  idTimeout = false;

  constructor(
    private http: HttpClient,
    private readonly localStorageService: LocalstorageService,
    private state: TransferState
  ) {}

  getArticleListByTag(tag: string): Observable<SimpleArticle[]> {
    type T = SimpleArticle[];
    const key = makeStateKey<T>('articleService_byTag_' + tag);
    const value: SimpleArticle[] = this.state.get<T>(key, null);
    if (value) {
      if (!this.albtTimeout) {
        setTimeout(() => {
          this.state.set<T>(key, null);
          this.albtTimeout = false;
        }, 2000);
        this.albtTimeout = true;
      }
      const convertedValue: T = [];
      value.forEach((x) =>
        convertedValue.push(Object.assign(new SimpleArticle(), x))
      );
      return of(convertedValue);
    }
    return this.http
      .get<T>(`/strapi-proxy/${this.tenantId}/article/tag/${tag}`)
      .pipe(
        map((articles) =>
          articles.map((article) => Object.assign(new SimpleArticle(), article))
        )
      )
      .pipe(
        map((articles: T) => {
          articles.sort(
            (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf()
          );
          articles.forEach((article: SimpleArticle) => {
            // Simple sorting strategy to provide some consistency when presenting articles
            // TODO Figure out better way to sort categories, either there or on the backend side
            article.categories.sort((a, b) => (a.name < b.name ? -1 : 1));
          });
          this.state.set<T>(key, articles);
          return articles;
        })
      );
  }

  getArticle(id: string): Observable<Article> {
    return this.http
      .get<Article>(`/strapi-proxy/${this.tenantId}/article/${id}`)
      .pipe(
        map((article) => {
          const res = Object.assign(new Article(), article);
          return res;
        })
      );
  }

  getGoodToKnowArticles(
    tag: string,
    count: number,
    tagId: string
  ): Observable<SimpleArticle[]> {
    type T = SimpleArticle[];
    const key = makeStateKey<T>('articleService_gtn_' + tagId);
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.gtnTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.gtnTimeout = false;
        }, 2000);
        this.gtnTimeout = true;
      }
      const convertedValue: T = [];
      value.forEach((x) =>
        convertedValue.push(Object.assign(new SimpleArticle(), x))
      );
      return of(convertedValue);
    }

    return this.http
      .get<T>(`/strapi-proxy/${this.tenantId}/article/tag/${tag}`)
      .pipe(
        map((articles) =>
          articles.map((article) => Object.assign(new SimpleArticle(), article))
        )
      )
      .pipe(
        map((articles) => {
          articles.sort(
            (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf()
          );
          this.state.set<T>(key, articles.slice(0, count));
          return articles.slice(0, count);
        })
      );
  }

  getRedirectArticleId(): string | null {
    return this.localStorageService.getItem(redirectArticleIdKey);
  }

  setRedirectArticleId(articleId: string | null) {
    return this.localStorageService.setItem(redirectArticleIdKey, articleId);
  }

  deleteRedirectArticleId() {
    return this.localStorageService.removeItem(redirectArticleIdKey);
  }
}
