import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SmallFooterService } from '@shared/service/small-footer/small-footer.service';
import {
  StrapiFooterSmall,
  StrapiGalleryComponent,
  StrapiImageComponent,
  StrapiParagraphComponent,
  StrapiStaticPageLinkComponent,
} from '@nursing/pwn-cms-model/lib';
import { ModalPopupsComponent } from '@app/shell/small-footer/modal-popups/modal-popups.component';
import { Logger } from '@core';
import { PageService } from '@app/@shared/service/page/page.service';

const log = new Logger('SmallFooterComponent');

@Component({
  selector: 'app-small-footer',
  templateUrl: './small-footer.component.html',
  styleUrls: [
    './small-footer.component.scss',
    './small-footer.component.mobile.scss',
  ],
})
export class SmallFooterComponent implements OnInit, OnDestroy {
  @Input()
  modals: ModalPopupsComponent;

  smallFooter: StrapiFooterSmall;

  subscription: Subscription;

  links: StrapiStaticPageLinkComponent[];

  constructor(
    private smallFooterService: SmallFooterService,
    private pageService: PageService
  ) {}

  ngOnInit(): void {
    log.debug('Component init started..');
    this.subscription = this.smallFooterService
      .getSmallFooter()
      .subscribe((response) => {
        this.smallFooter = response;
        this.links = [];

        this.smallFooter.links?.forEach((l) => {
          if (l.page) {
            this.pageService.getPageById(l.page.id).subscribe((p) => {
              l.page = p;
              this.links.push(l);
            });
          } else {
            this.links.push(l);
          }
        });
      });
  }

  ngOnDestroy(): void {
    log.debug('Component destroy started ..');
    this.subscription?.unsubscribe();
  }
}
