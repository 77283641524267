import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { SassHelperComponent } from './providers/sass-helper/sass-helper.component';

import { ToastContainerComponent } from '@shared/toast-container/toast-container.component';
import { ImgUrlWithSizeDirective } from '@shared/directives/img-url-with-size.directive';
import { StrapiImageDirective } from '@shared/directives/strapi-image.directive';

@NgModule({
  imports: [CommonModule, NgbToastModule],
  declarations: [
    ToastContainerComponent,
    ImgUrlWithSizeDirective,
    SassHelperComponent,
    StrapiImageDirective,
  ],
  exports: [
    ToastContainerComponent,
    ImgUrlWithSizeDirective,
    SassHelperComponent,
    StrapiImageDirective,
  ],
})
export class SharedModule {}
