import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StrapiStaticTextComponent } from '@nursing/pwn-cms-model/lib';

@Component({
  selector: 'app-static-text',
  templateUrl: './static-text.component.html',
  styleUrls: ['./static-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticTextComponent {
  @Input()
  component: StrapiStaticTextComponent;

  constructor() {}
}
