<div [ngSwitch]="currentState?.type" class="breadcrumb-container">
  <p *ngSwitchCase="BreadcrumbType.article">
    <a [routerLink]="'/'">HOME</a>
    <span *ngFor="let node of articleTreeNode?.getPath()">
      -
      <a [routerLink]="['/artykuly', slugIdMap.get(node.element.id)]">{{
        node.element.shortName || node.element.displayName | uppercase
      }}</a>
    </span>
    -
    <a [routerLink]="['/artykul', article.slugId]">
      {{ article.title }}
    </a>
  </p>
  <p *ngSwitchCase="BreadcrumbType.author">
    <a [routerLink]="'/'">HOME</a>
    -
    <a [routerLink]="'/nasi-autorzy'">AUTORZY</a>
    -
    <a [routerLink]="['/autor', UrlUtils.toSlugId(author.name, author.id)]">{{
      author.name
    }}</a>
  </p>
  <p *ngSwitchCase="BreadcrumbType.category">
    <span *ngIf="category.parent">
      <a [routerLink]="'/'">HOME</a>
      <span *ngFor="let node of category.getPath()">
        -
        <a
          [routerLink]="[
            isBooks ? '/ksiazki' : '/artykuly',
            slugIdMap.get(node.element.id)
          ]"
          >{{
            node.element.shortName || node.element.displayName | uppercase
          }}</a
        >
      </span>
    </span>
  </p>
  <p *ngSwitchCase="BreadcrumbType.event">
    <a [routerLink]="'/'">HOME</a>
    -
    <a [routerLink]="'/wydarzenia'">WYDARZENIA</a>
    -
    <a
      [routerLink]="['/wydarzenia', UrlUtils.toSlugId(event.title, event.id)]"
      >{{ event.title }}</a
    >
  </p>
  <p *ngSwitchCase="BreadcrumbType.jobOffer">
    <a [routerLink]="'/'">HOME</a>
    -
    <a [routerLink]="'/oferty-pracy'">OFERTY PRACY</a>
    -
    <a
      [routerLink]="[
        '/oferty-pracy',
        UrlUtils.toSlugId(jobOffer.title, jobOffer.id)
      ]"
      >{{ jobOffer.title }}</a
    >
  </p>
</div>
