import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PwnAgreements, StrapiConfiguration } from '@nursing/pwn-cms-model/lib';
import { ConfigurationService } from '../configuration/configuration.service';
import { catchError, mergeMap, timeout } from 'rxjs/operators';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { PlatformService } from '../ssr/platform.service';

const AGREEMENTS_KEY = makeStateKey<PwnAgreements>(
  'agreementsService_agreements'
);

@Injectable({
  providedIn: 'root',
})
export class AgreementsService {
  agreementsSubject = new BehaviorSubject<PwnAgreements>(null);
  agreementsInitialized = false;

  constructor(
    private http: HttpClient,
    private configurationService: ConfigurationService,
    private state: TransferState
  ) {}

  getAgreements(): Observable<PwnAgreements> {
    return this.configurationService.getConfiguration().pipe(
      mergeMap((config) => {
        if (!config) {
          return EMPTY;
        }

        if (!this.agreementsInitialized) {
          this.loadAgreements(config);
          this.agreementsInitialized = true;
        }
        return this.agreementsSubject;
      })
    );
  }

  loadAgreements(config: StrapiConfiguration) {
    const agreements = this.state.get<PwnAgreements>(AGREEMENTS_KEY, null);
    if (!agreements && PlatformService.isBrowser) {
      this.http
        .get<PwnAgreements>('/pwn-user/agreements/' + config.tenantId)
        .subscribe(
          (agreements) => {
            this.agreementsSubject.next(agreements);
            this.state.set<PwnAgreements>(AGREEMENTS_KEY, agreements);
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.agreementsSubject.next(agreements);
      this.state.set(AGREEMENTS_KEY, null);
    }
  }
}
