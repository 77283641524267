<div class="sources-container" *ngIf="visible">
  <div *ngFor="let item of items" class="item-container">
    <div class="item-left-container">
      <div class="item-inner-container">
        <a href="{{ item.linkUrl }}" target="_blank" rel="noopener noreferrer">
          <img
            class="item-image"
            *ngIf="item.imageUrl"
            [imgUrlWithSize]="imageFormat"
            [imgUrl]="item.imageUrl"
            [style]="'object-fit: ' + component.displayType"
            loading="lazy"
          />
        </a>
      </div>
    </div>
    <div class="item-right-container">
      <a
        class="item-title"
        *ngIf="item.isMagazine"
        href="{{ item.linkUrl }}"
        target="_blank"
        rel="noopener noreferrer"
      >
        Artykuł z magazynu “{{ item.title }}”
      </a>
      <a
        class="item-title"
        *ngIf="!item.isMagazine"
        href="{{ item.linkUrl }}"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>
          <span>Artykuł z książki “{{ item.title }}”</span>
        </div>
      </a>
      <a
        class="item-button"
        href="{{ item.linkUrl }}"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>{{ item.linkButtonLabel }}</span>
      </a>
    </div>
  </div>
  <div [innerHtml]="getSourcesExternalHtml()"></div>
</div>
