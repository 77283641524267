/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@env/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}
if (typeof document !== 'undefined' && document != null) {
  document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.log(err));
  });
}

// TODO Could be uncommented after providing S3 support in the favicon endpoint
// const favIcon: HTMLLinkElement = document.querySelector('#favIcon');
// favIcon.href = environment.serverUrl + '/strapi-proxy/' + environment.tenantId + '/image/favicon';
