<div *ngIf="mainMenu" (mouseleave)="onMouseLeave()">
  <div
    [ngClass]="{
      topnav: true,
      alwaysVisible: mainMenu.alwaysVisible
    }"
  >
    <div class="logo">
      <a [routerLink]="['/']">
        <img
          class="logoImg"
          src="{{
            logo?.getPublicImageUrl(
              getServerUrl() + '/strapi-proxy/' + tenantId
            )
          }}"
          alt="Nursing - Portal dla pielęgniarek, pielęgniarzy, położnych, studentów"
        />
      </a>
    </div>
    <div *ngFor="let link of elements">
      <app-category-menu-entry
        [link]="link"
        [mainMenuState]="mainMenuState"
      ></app-category-menu-entry>
    </div>
    <app-breadcrumb></app-breadcrumb>
    <span class="menu search">
      <a class="search-icon" [routerLink]="['/wyniki-wyszukiwania']">
        <img
          src="assets/icons/mobile_global_search.png"
          alt="Ikonka globalnej wyszukiwarki"
        />
      </a>
    </span>
    <span class="menu material-icons" (click)="openMenu(mainMenuContent)"
      >menu</span
    >
    <div class="account-options-desktop">
      <app-global-search-bar
        (isOpened)="globalSearchBarOpened = $event"
        width="300"
        height="56"
      ></app-global-search-bar>
      <a
        [ngClass]="{ opened: globalSearchBarOpened }"
        (click)="goToLogin()"
        [routerLink]="['/logowanie']"
        *ngIf="isLoggedOut$ | async"
      >
        <img src="assets/icons/account_dark.png" alt="Ikonka konta" />
        <span>Zaloguj się</span>
      </a>
      <a
        [ngClass]="{ opened: globalSearchBarOpened }"
        [routerLink]="['/konto']"
        *ngIf="isLoggedIn$ | async"
      >
        <img src="assets/icons/account_dark.png" alt="Ikonka konta" />
        <span>Moje konto</span>
      </a>
      <a
        [ngClass]="{ opened: globalSearchBarOpened }"
        *ngIf="isLoggedIn$ | async"
        (click)="logOut()"
      >
        <img src="assets/icons/logout_dark.png" alt="Ikonka konta" />
        <span>Wyloguj</span>
      </a>
    </div>
  </div>
  <div>
    <app-category-popup
      [mainMenuState]="mainMenuState"
      [mainMenuLayout]="mainMenu.layout"
      [alwaysVisible]="mainMenu.alwaysVisible"
    ></app-category-popup>
  </div>
</div>

<ng-template #mainMenuContent let-modal>
  <button
    class="btn"
    style="
      font-family: Material Icons;
      font-weight: 400;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-smoothing: antialiased;
      width: fit-content;
      color: var(--secondary_font_color);
      font-size: 38px;
      text-align: right;
      margin: 30px 13px 0 auto;
    "
    (click)="closeModal()"
  >
    close
  </button>
  <div class="menu-options">
    <div *ngFor="let link of elements">
      <a
        *ngIf="link.type === componentTypes.CategoryLink"
        [routerLink]="['/artykuly', slugIdMap.get(link.targetId)]"
        (click)="modal.close()"
      >
        {{ link.displayName | uppercase }}
      </a>
      <a
        *ngIf="
          link.type === componentTypes.StaticPageLink &&
          link.external &&
          !link.page
        "
        href="{{ link.targetId }}"
        target="_blank"
        rel="noopener noreferrer"
        (click)="modal.close()"
      >
        {{ link.displayName | uppercase }}
      </a>
      <a
        *ngIf="
          link.type === componentTypes.StaticPageLink &&
          !link.external &&
          !link.page
        "
        [routerLink]="[link.targetId]"
        (click)="modal.close()"
      >
        {{ link.displayName | uppercase }}
      </a>
      <a
        *ngIf="
          link.type === componentTypes.StaticPageLink &&
          !link.external &&
          link.page
        "
      >
        <app-modal-popups
          [displayName]="link.displayName"
          [pageContent]="link.page?.content"
        ></app-modal-popups>
      </a>
    </div>
  </div>
  <div class="account-options">
    <a
      [routerLink]="['/logowanie']"
      *ngIf="isLoggedOut$ | async"
      (click)="goToLogin()"
      ><img src="assets/icons/account.png" alt="Ikonka konta" />Zaloguj się</a
    >
    <a
      [routerLink]="['/konto']"
      *ngIf="isLoggedIn$ | async"
      (click)="modal.close()"
      ><img src="assets/icons/account.png" alt="Ikonka konta" />Moje konto</a
    >
    <a *ngIf="isLoggedIn$ | async" (click)="logOut()"
      ><img src="assets/icons/logout.png" alt="Ikonka wylogowania" />Wyloguj</a
    >
  </div>
</ng-template>
