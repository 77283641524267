import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageService } from '@shared/service/page/page.service';
import {
  StrapiArticleListPage,
  StrapiCategory,
  StrapiComponentTypeEnum,
  StrapiListingPageViewEnum,
  UrlUtils,
} from '@nursing/pwn-cms-model/lib';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, zip } from 'rxjs';
import { StrapiModelTypeEnum } from '@nursing/pwn-cms-model/lib/model/strapi/enums/strapi-model-type-enum';
import { SearchBarInputData } from '@shared/model/search-bar/search-bar-input-data';
import { CategoryService } from '@shared/service/category/category.service';
import { AdService } from '@app/@shared/service/ad/ad.service';
import { Location } from '@angular/common';
import { UserProductService } from '@shared/service/user-product/user-product.service';
import { switchMap, tap } from 'rxjs/operators';
import { MetaDataService } from '@app/@shared/service/meta-data-service/meta-data.service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss', './articles.component.mobile.scss'],
})
export class ArticlesComponent implements OnInit, OnDestroy {
  searchData: SearchBarInputData;
  categoryId: string;
  categories: StrapiCategory[] = [];
  userFavoriteArticleIds: string[] = [];

  articleListPageSubscription: Subscription;
  categiriesSubscription: Subscription;
  userFavoriteArticleIds$: Subscription;
  articleListPage: StrapiArticleListPage;

  strapiModelType = StrapiModelTypeEnum.Article;
  listingPageView = StrapiListingPageViewEnum.list; // default is list

  componentTypes: typeof StrapiComponentTypeEnum = StrapiComponentTypeEnum;
  StrapiListingPageViewEnum = StrapiListingPageViewEnum;

  constructor(
    public pageService: PageService,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private metaDataService: MetaDataService,
    private adService: AdService,
    private router: Router,
    private location: Location,
    private userProductService: UserProductService
  ) {}

  ngOnInit(): void {
    this.categiriesSubscription = this.route.paramMap
      .pipe(
        switchMap((params) => {
          const urlParam = params.get('categoryId');
          if (urlParam == null || urlParam === undefined) {
            return this.categoryService.getCategories().pipe(
              tap({
                next: (categories) => {
                  for (const cat of categories) {
                    if (cat.displayName === 'Artykuły') {
                      this.router.navigate(
                        [
                          '/artykuly',
                          UrlUtils.toSlugId(cat.displayName, cat.id),
                        ],
                        {
                          replaceUrl: true,
                        }
                      );
                      return;
                    }
                  }
                },
              })
            );
          } else {
            this.categoryId = UrlUtils.parseId(params.get('categoryId'));
            return zip(
              this.categoryService.getCategoryById(this.categoryId),
              this.pageService.getArticleListPage()
            ).pipe(
              tap(([cat, articleListPage]) => {
                const properUrl =
                  '/artykuly/' + UrlUtils.toSlugId(cat.displayName, cat.id);
                if (!this.location.path().startsWith(properUrl)) {
                  this.location.go(properUrl);
                  this.metaDataService.createOrUpdateCanonical(properUrl);
                }
                this.listingPageView = cat.listingPageView;
                this.searchData = new SearchBarInputData(
                  [this.categoryId],
                  [],
                  [this.strapiModelType],
                  params.get('tagId')
                );
                this.adService.setAdContextWithIds([this.categoryId]);
                this.categories.push(cat);
                this.articleListPage = articleListPage;
              })
            );
          }
        })
      )
      .subscribe();

    this.userFavoriteArticleIds$ = this.userProductService._userFavoriteArticlesIds.subscribe(
      (value) => {
        this.userFavoriteArticleIds = value;
      }
    );
  }

  getSlugId(categoryId: string): string {
    for (const cat of this.categories) {
      if (cat.id === categoryId) {
        return UrlUtils.toSlugId(cat.displayName, cat.id);
      }
    }
    return categoryId;
  }

  ngOnDestroy() {
    this.articleListPageSubscription?.unsubscribe();
    this.categiriesSubscription?.unsubscribe();
    this.userFavoriteArticleIds$?.unsubscribe();
  }
}
