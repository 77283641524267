import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LargeFooterService } from '@shared/service/footer-large/large-footer.service';
import {
  StrapiComponentTypeEnum,
  StrapiFooterLarge,
} from '@nursing/pwn-cms-model/lib';

@Component({
  selector: 'app-large-footer',
  templateUrl: './large-footer.component.html',
  styleUrls: [
    './large-footer.component.scss',
    './large-footer.component.mobile.scss',
  ],
})
export class LargeFooterComponent implements OnInit, OnDestroy {
  StrapiComponentTypeEnum = StrapiComponentTypeEnum;
  largeFooter: StrapiFooterLarge;
  subscription: Subscription;

  constructor(private largeFooterService: LargeFooterService) {}

  ngOnInit(): void {
    this.subscription = this.largeFooterService
      .getLargeFooter()
      .subscribe((largeFooter) => {
        this.largeFooter = largeFooter;
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
