import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import {
  Article,
  Author,
  StrapiAdComponent,
  StrapiArticleBodyComponent,
  StrapiCategoryLinkComponent,
  StrapiClosedMarkerComponent,
  StrapiComponentTypeEnum,
  StrapiCustomHtmlComponent,
  StrapiGalleryComponent,
  StrapiGoodToKnowArticlesComponent,
  StrapiHotArticlesComponent,
  StrapiImageComponent,
  StrapiMicroservicesComponent,
  StrapiNewsletterComponent,
  StrapiParagraphComponent,
  StrapiPromotedBooksComponent,
  StrapiSocialMediaComponent,
  StrapiSourcesComponent,
  StrapiStaticPageLinkComponent,
  StrapiStaticTextComponent,
  StrapiSubCategoryListComponent,
  StrapiTopArticlesComponent,
} from '@nursing/pwn-cms-model/lib';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PlatformService } from '@app/@shared/service/ssr/platform.service';
import videojs from 'video.js';

declare const renderMathInElement: Function;

@Component({
  selector: 'app-article-main-content',
  templateUrl: './article-main-content.component.html',
  styleUrls: [
    './article-main-content.component.scss',
    './article-main-content.component.mobile.scss',
  ],
})
export class ArticleMainContentComponent implements OnDestroy, AfterViewInit {
  _article: Article;
  _components: (
    | StrapiHotArticlesComponent
    | StrapiCategoryLinkComponent
    | StrapiGoodToKnowArticlesComponent
    | StrapiTopArticlesComponent
    | StrapiMicroservicesComponent
    | StrapiStaticPageLinkComponent
    | StrapiParagraphComponent
    | StrapiGalleryComponent
    | StrapiPromotedBooksComponent
    | StrapiAdComponent
    | StrapiImageComponent
    | StrapiClosedMarkerComponent
    | StrapiSubCategoryListComponent
    | StrapiNewsletterComponent
    | StrapiArticleBodyComponent
    | StrapiStaticTextComponent
    | StrapiSourcesComponent
    | Author[]
    | StrapiSocialMediaComponent
    | StrapiCustomHtmlComponent
  )[];

  componentTypes: typeof StrapiComponentTypeEnum = StrapiComponentTypeEnum;
  lead: SafeHtml;
  shortDescription: SafeHtml;
  options: any;
  player: any;

  @Input()
  set components(
    components: (
      | StrapiHotArticlesComponent
      | StrapiCategoryLinkComponent
      | StrapiGoodToKnowArticlesComponent
      | StrapiTopArticlesComponent
      | StrapiMicroservicesComponent
      | StrapiStaticPageLinkComponent
      | StrapiParagraphComponent
      | StrapiGalleryComponent
      | StrapiPromotedBooksComponent
      | StrapiAdComponent
      | StrapiImageComponent
      | StrapiClosedMarkerComponent
      | StrapiSubCategoryListComponent
      | StrapiNewsletterComponent
      | StrapiArticleBodyComponent
      | StrapiStaticTextComponent
      | Author[]
      | StrapiSocialMediaComponent
      | StrapiCustomHtmlComponent
    )[]
  ) {
    if (components && components.length) {
      this._components = components;
    }
  }

  @Input() set article(value: Article) {
    if (value) {
      this._article = value;
      this.lead = this.sanitizer.bypassSecurityTrustHtml(this._article?.lead);
      this.shortDescription = this.sanitizer.bypassSecurityTrustHtml(
        this._article?.shortDescription
      );
    }
  }
  ngAfterViewInit(): void {
    this.options = {};
    this.options.controls = true;
    setTimeout(() => {
      const titleTrimmed = this._article?.title?.trim();
      if (typeof document !== 'undefined' && document !== null) {
        if (document.getElementById('simpleplayer') !== null) {
          if (
            document.getElementById('simpleplayer').getAttribute('alt') === ''
          ) {
            document
              .getElementById('simpleplayer')
              .setAttribute('alt', titleTrimmed + ' - video');
          }
        }
        if (document.getElementById('videoplayer') !== null) {
          if (
            document.getElementById('videoplayer').getAttribute('alt') === ''
          ) {
            document
              .getElementById('videoplayer')
              .setAttribute('alt', titleTrimmed + ' - video');
          }
          this.player = videojs(
            document.getElementById('videoplayer'),
            this.options,
            function onPlayerReady() {
              document.getElementById('videoplayer').className =
                'video-js vjs_video_3-dimensions vjs-controls-enabled vjs-workinghover vjs-v8 vjs-has-started vjs-paused vjs-user-inactive';
            }
          );
        }
      }
    }, 1000);
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }

  constructor(private sanitizer: DomSanitizer) {}

  isBrowser(): boolean {
    return PlatformService.isBrowser;
  }
}
