import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ElkSearchResp,
  LogicalOperatorEnum,
  SearchCriteria,
  SearchFieldNameEnum,
  SearchLogicalComponent,
  SearchMatchQuery,
  SearchMultiMatchQuery,
  SearchQueryTypeEnum,
  SearchRangeQuery,
  SearchTermQuery,
} from '@nursing/pwn-cms-model/lib';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Logger } from '@core';

const log = new Logger('SearchService');

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  searchResult: BehaviorSubject<SearchCriteria>;
  tenantId = environment.tenantId;

  _ignoreNextRouterEvent = false;
  private mainQuerySubject = new BehaviorSubject<string>(null);
  public mainQuery$: Observable<string> = this.mainQuerySubject.asObservable();

  constructor(private http: HttpClient) {
    this.searchResult = new BehaviorSubject(null);
  }

  search(params: SearchCriteria): Observable<ElkSearchResp> {
    return this.http.post<any>(`/pwn-search/search/${this.tenantId}`, params);
  }

  searchType(
    params: SearchCriteria,
    tenantId: string,
    email: string,
    searchType: string
  ): Observable<ElkSearchResp> {
    return this.http.post<ElkSearchResp>(
      `/pwn-search/search/${tenantId}/${email}/${searchType}`,
      params
    );
  }

  getSearchCriteria() {
    return this.searchResult.asObservable();
  }

  setSearchCriteria(criteria: SearchCriteria): void {
    this.searchResult.next(criteria);
  }

  setMainQuery(mainQuery: string): void {
    this.mainQuerySubject.next(mainQuery);
  }

  getFilterCriteriaFromInputData(
    model?: string[],
    categoryIds?: string[],
    authorIds?: string[],
    tagId?: string,
    sourceId?: string,
    type?: string,
    voivodeship?: string,
    dateFrom?: Date,
    dateTo?: Date,
    expert?: boolean,
    inArticleIds?: string[]
  ): (SearchTermQuery | SearchRangeQuery | SearchLogicalComponent)[] {
    const criteria: (
      | SearchTermQuery
      | SearchRangeQuery
      | SearchLogicalComponent
    )[] = [];
    if (model) {
      const modelOr: SearchLogicalComponent = {
        type: SearchQueryTypeEnum.Bool,
        logicalOperator: LogicalOperatorEnum.Or,
        criteria: [],
      };

      for (const modelType of model) {
        modelOr.criteria.push({
          type: SearchQueryTypeEnum.Term,
          field: SearchFieldNameEnum.Model,
          value: modelType,
        });
      }

      criteria.push(modelOr);
    }
    if (categoryIds && categoryIds.length) {
      for (const categoryId of categoryIds) {
        if (categoryId) {
          criteria.push({
            type: SearchQueryTypeEnum.Term,
            field: SearchFieldNameEnum.CategoriesId,
            value: categoryId,
          });
        }
      }
    }
    if (authorIds) {
      for (const authorId of authorIds) {
        criteria.push({
          type: SearchQueryTypeEnum.Term,
          field: SearchFieldNameEnum.AuthorsId,
          value: authorId,
        });
      }
    }
    if (tagId) {
      criteria.push({
        type: SearchQueryTypeEnum.Term,
        field: SearchFieldNameEnum.TagsId,
        value: tagId,
      });
    }
    if (sourceId) {
      criteria.push({
        type: SearchQueryTypeEnum.Term,
        field: SearchFieldNameEnum.SourcesId,
        value: sourceId,
      });
    }
    if (type) {
      criteria.push({
        type: SearchQueryTypeEnum.Term,
        field: SearchFieldNameEnum.Type,
        value: type,
      });
    }
    if (voivodeship) {
      criteria.push({
        type: SearchQueryTypeEnum.Term,
        field: SearchFieldNameEnum.Voivodeship,
        value: voivodeship,
      });
    }
    if (expert) {
      criteria.push({
        type: SearchQueryTypeEnum.Term,
        field: SearchFieldNameEnum.Expert,
        value: expert.toString(),
      });
    }
    if (dateFrom || dateTo) {
      const dateOr: SearchLogicalComponent = {
        type: SearchQueryTypeEnum.Bool,
        logicalOperator: LogicalOperatorEnum.Or,
        criteria: [],
      };
      dateOr.criteria.push({
        type: SearchQueryTypeEnum.Not,
        criteria: {
          type: SearchQueryTypeEnum.Exists,
          field: SearchFieldNameEnum.Date,
        },
      });

      const dateAnd: SearchLogicalComponent = {
        type: SearchQueryTypeEnum.Bool,
        logicalOperator: LogicalOperatorEnum.And,
        criteria: [],
      };

      if (dateFrom) {
        dateAnd.criteria.push({
          type: SearchQueryTypeEnum.Range,
          field: SearchFieldNameEnum.Date,
          gte: dateFrom.getTime().toString(),
        });
      }
      if (dateTo) {
        dateAnd.criteria.push({
          type: SearchQueryTypeEnum.Range,
          field: SearchFieldNameEnum.Date,
          lte: dateTo.getTime().toString(),
        });
      }

      if (dateAnd.criteria?.length) {
        dateOr.criteria.push(dateAnd);
      }

      criteria.push(dateOr);
    }
    if (inArticleIds && inArticleIds.length) {
      const inArticleIdsCriteria: SearchTermQuery[] = [];
      inArticleIds.forEach((articleId) => {
        const searchTermQuery: SearchTermQuery = {
          type: SearchQueryTypeEnum.Term,
          field: SearchFieldNameEnum.Id,
          value: articleId,
        };
        inArticleIdsCriteria.push(searchTermQuery);
      });
      criteria.push({
        type: SearchQueryTypeEnum.Bool,
        logicalOperator: LogicalOperatorEnum.Or,
        criteria: inArticleIdsCriteria,
      });
    }
    if (inArticleIds && inArticleIds.length === 0) {
      const inArticleIdsCriteria: SearchTermQuery[] = [];
      const searchTermQuery: SearchTermQuery = {
        type: SearchQueryTypeEnum.Term,
        field: SearchFieldNameEnum.Id,
        value: '',
      };
      inArticleIdsCriteria.push(searchTermQuery);
      criteria.push({
        type: SearchQueryTypeEnum.Bool,
        logicalOperator: LogicalOperatorEnum.Or,
        criteria: inArticleIdsCriteria,
      });
    }
    return criteria;
  }

  getQuery(
    mainQuery?: string,
    cityQuery?: string,
    workplaceQuery?: string
  ): SearchLogicalComponent | undefined {
    if (mainQuery || cityQuery || workplaceQuery) {
      return {
        type: SearchQueryTypeEnum.Bool,
        logicalOperator: LogicalOperatorEnum.And,
        criteria: this.getQueryCriteriaFromInputData(
          mainQuery,
          cityQuery,
          workplaceQuery
        ),
      };
    } else {
      return undefined;
    }
  }

  getQueryCriteriaFromInputData(
    mainQuery?: string,
    cityQuery?: string,
    workplaceQuery?: string
  ): (SearchLogicalComponent | SearchMatchQuery | SearchMultiMatchQuery)[] {
    const queryCriteria: (
      | SearchLogicalComponent
      | SearchMultiMatchQuery
      | SearchMatchQuery
    )[] = [];
    if (mainQuery && !(cityQuery || workplaceQuery)) {
      queryCriteria.push({
        type: SearchQueryTypeEnum.MultiMatch,
        value: mainQuery,
      });
    } else if (cityQuery || workplaceQuery) {
      if (mainQuery) {
        const searchInFields = [
          SearchFieldNameEnum.Title,
          SearchFieldNameEnum.Description,
          SearchFieldNameEnum.Content,
          SearchFieldNameEnum.Employer,
          SearchFieldNameEnum.Position,
        ];
        const logicalComponent: SearchLogicalComponent = {
          type: SearchQueryTypeEnum.Bool,
          logicalOperator: LogicalOperatorEnum.Or,
          criteria: [],
        };
        searchInFields.forEach((searchInField) => {
          logicalComponent.criteria.push({
            type: SearchQueryTypeEnum.Match,
            value: mainQuery,
            field: searchInField,
          });
        });
        queryCriteria.push(logicalComponent);
      }
      if (cityQuery && !workplaceQuery) {
        queryCriteria.push({
          type: SearchQueryTypeEnum.Match,
          value: cityQuery,
          operator: LogicalOperatorEnum.And,
          field: SearchFieldNameEnum.City,
        });
      } else {
        queryCriteria.push({
          type: SearchQueryTypeEnum.Bool,
          logicalOperator: LogicalOperatorEnum.Or,
          criteria: [
            {
              type: SearchQueryTypeEnum.Match,
              value: workplaceQuery,
              operator: LogicalOperatorEnum.And,
              field: SearchFieldNameEnum.City,
            },
            {
              type: SearchQueryTypeEnum.Match,
              value: workplaceQuery,
              operator: LogicalOperatorEnum.And,
              field: SearchFieldNameEnum.Voivodeship,
            },
          ],
        });
      }
    }
    return queryCriteria;
  }

  ignoreNextRouterEvent() {
    this._ignoreNextRouterEvent = true;
  }

  shouldIgnoreNextEventAndReset() {
    const result = this._ignoreNextRouterEvent;
    this._ignoreNextRouterEvent = false;
    return result;
  }
}
