import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SimpleArticle, StrapiImageSizeEnum } from '@nursing/pwn-cms-model/lib';
import { Observable, Subscription } from 'rxjs';

import { environment } from '@env/environment';
import { CategoryService } from '@app/@shared/service/category/category.service';
import { AuthenticationService } from '@shared/service/authentication/authentication.service';
import { UserProductService } from '@shared/service/user-product/user-product.service';

@Component({
  selector: 'app-hot-article',
  templateUrl: './hot-article.component.html',
  styleUrls: [
    './hot-article.component.scss',
    './hot-article.component.mobile.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotArticleComponent implements OnInit, OnDestroy {
  readonly tenantId = environment.tenantId;
  @Input()
  article: SimpleArticle;

  @Input()
  imageSize: string;

  @Input()
  readLabel: string;

  @Input()
  requirePreload: boolean;

  categoriesSub: Subscription;
  userFavoriteArticleIds$: Subscription;

  slugIdMap = new Map();

  isLoggedIn$: Observable<boolean>;
  userFavoriteArticlesIds: string[] = [];

  public imageFormat = StrapiImageSizeEnum.small;

  constructor(
    private categoryService: CategoryService,
    private authenticationService: AuthenticationService,
    private userProductService: UserProductService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.categoriesSub = this.categoryService
      .getCategories()
      .subscribe((categories) => {
        categories.forEach((cat) => {
          this.slugIdMap.set(cat.id, this.categoryService.getSlugId(cat));
          this.cd.markForCheck();
        });
      });
    this.isLoggedIn$ = this.authenticationService.isLoggedIn$;

    this.userFavoriteArticleIds$ = this.userProductService._userFavoriteArticlesIds.subscribe(
      (value) => {
        this.userFavoriteArticlesIds = value;
        this.cd.markForCheck();
      }
    );
  }

  ngOnDestroy(): void {
    this.categoriesSub?.unsubscribe();
  }

  getServerUrl(): string {
    return environment.serverUrl;
  }

  isFavorite(articleId: string): boolean {
    if (this.userFavoriteArticlesIds) {
      const index = this.userFavoriteArticlesIds.findIndex(
        (favoriteId) => favoriteId === articleId
      );
      return index > -1;
    } else {
      return false;
    }
  }

  addToFavorite(articleId: string) {
    if (this.isLoggedIn$) {
      this.userProductService
        .addArticleToFavorite(articleId)
        .subscribe((res) => {
          if (!this.userFavoriteArticlesIds) {
            this.userFavoriteArticlesIds = [];
          }
          if (
            res &&
            !this.userFavoriteArticlesIds.find(
              (favoriteId) => favoriteId === articleId
            )
          ) {
            this.userFavoriteArticlesIds.push(articleId);
          }
          this.userProductService.updateUserFavoriteArticlesIds(
            this.userFavoriteArticlesIds
          );
          this.cd.markForCheck();
        });
    }
  }

  removeFromFavorite(articleId: string) {
    if (this.isLoggedIn$) {
      this.userProductService
        .removeArticleFromFavorite(articleId)
        .subscribe((res) => {
          if (res && this.userFavoriteArticlesIds) {
            const productIndex = this.userFavoriteArticlesIds.indexOf(
              articleId
            );
            if (productIndex > -1) {
              this.userFavoriteArticlesIds.splice(productIndex, 1);
            }
          }
          this.userProductService.updateUserFavoriteArticlesIds(
            this.userFavoriteArticlesIds
          );
          this.cd.markForCheck();
        });
    }
  }
}
