import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { StrapiBook } from '@nursing/pwn-cms-model/lib';
import { environment } from '@env/environment';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BookService {
  topBooksTimeout = false;
  categoryTimeout = false;
  bookTimeout = false;
  private readonly tenantId = environment.tenantId;

  constructor(private http: HttpClient, private state: TransferState) {}

  getTopBooks(count: number): Observable<StrapiBook[]> {
    type T = StrapiBook[];
    const key = makeStateKey<T>('BookService_getTopBooks' + count);
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.topBooksTimeout) {
        setTimeout(() => {
          this.state.set<T>(key, null);
          this.topBooksTimeout = false;
        }, 2000);
        this.topBooksTimeout = true;
      }
      return of(value);
    }
    return this.http
      .get<T>(`/strapi-proxy/${this.tenantId}/book/top/${count}`)
      .pipe(
        map((x) => {
          this.state.set<T>(key, x);
          return x;
        })
      );
  }

  getBooksByCategory(
    category: string,
    count: number
  ): Observable<StrapiBook[]> {
    type T = StrapiBook[];
    const key = makeStateKey<T>(
      'BookService_getBooksByCategory_' + category + '_' + count
    );
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.categoryTimeout) {
        setTimeout(() => {
          this.state.set<T>(key, null);
          this.categoryTimeout = false;
        }, 2000);
        this.categoryTimeout = true;
      }
      return of(value);
    }

    return this.http
      .get<T>(`/strapi-proxy/${this.tenantId}/book/${category}/top/${count}`)
      .pipe(
        map((x) => {
          this.state.set<T>(key, null);
          return x;
        })
      );
  }

  getBookById(id: string): Observable<StrapiBook> {
    type T = StrapiBook;
    const key = makeStateKey<T>('BookService_getBookById_' + id);
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.bookTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.bookTimeout = false;
        }, 2000);
        this.bookTimeout = true;
      }
      return of(value);
    }
    return this.http
      .get<T>(`/strapi-proxy/${this.tenantId}/book/byId/${id}`)
      .pipe(
        map((x) => {
          this.state.set<T>(key, x);
          return x;
        })
      );
  }
}
