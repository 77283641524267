import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CategoryService } from '@app/@shared/service/category/category.service';
import { SimpleArticle } from '@nursing/pwn-cms-model/lib';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-top-article',
  templateUrl: './top-article.component.html',
  styleUrls: [
    './top-article.component.scss',
    './top-article.component.mobile.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopArticleComponent implements OnInit, OnDestroy {
  _article: SimpleArticle;

  @Input() set article(article: SimpleArticle) {
    this._article = article;
  }

  @Input()
  showDates: boolean;

  slugIdMap = new Map();

  categoriesSub: Subscription;

  constructor(
    private categoryService: CategoryService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.categoriesSub = this.categoryService
      .getCategories()
      .subscribe((categories) => {
        categories.forEach((cat) => {
          this.slugIdMap.set(cat.id, this.categoryService.getSlugId(cat));
          this.cd.markForCheck();
        });
      });
  }

  ngOnDestroy(): void {
    this.categoriesSub?.unsubscribe();
  }
}
