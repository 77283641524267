import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  SearchCriteria,
  StrapiConfiguration,
} from '@nursing/pwn-cms-model/lib';
import { ConfigurationService } from '@shared/service/configuration/configuration.service';
import { SearchService } from '@shared/service/elastic-search/search.service';

@Component({
  selector: 'app-global-search-bar',
  templateUrl: './global-search-bar.component.html',
  styleUrls: [
    './global-search-bar.component.scss',
    './global-search-bar.component.mobile.scss',
  ],
})
export class GlobalSearchBarComponent implements OnInit {
  @ViewChild('container') container: ElementRef;
  @ViewChild('input') input: ElementRef;

  @Output()
  isOpened = new EventEmitter<boolean>();

  globalSearchBarOpened = false;

  searchPhrase: string;

  searchCriteria: SearchCriteria = {};

  itemsPerPage: number;

  searching: boolean;

  mainQuery: string;

  constructor(
    private router: Router,
    private configurationService: ConfigurationService,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.configurationService
      .getConfiguration()
      .subscribe((res: StrapiConfiguration) => {
        this.itemsPerPage = res?.searchRowsPerPage;
      });
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {
    if (
      !this.container.nativeElement.contains(target) &&
      target.className.indexOf('search-input') < 0 &&
      target.className.indexOf('search-icon') < 0
    ) {
      if (this.globalSearchBarOpened) {
        this.setSearchBarStatus((this.globalSearchBarOpened = false));
      }
    }

    if (
      (target.className.indexOf('search-input') >= 0 ||
        target.className.indexOf('search-icon') >= 0) &&
      !this.globalSearchBarOpened
    ) {
      this.setSearchBarStatus((this.globalSearchBarOpened = true));
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 300);
    }
  }

  search(): void {
    if (this.globalSearchBarOpened) {
      this.searchCriteria.query = this.searchService.getQuery(this.mainQuery);
      this.searchCriteria.pagination = {
        from: 0,
        size: this.itemsPerPage,
      };
      this.searchService.setSearchCriteria(this.searchCriteria);
      this.searchService.setMainQuery(this.mainQuery);
      setTimeout(() => {
        this.setSearchBarStatus((this.globalSearchBarOpened = false));
      }, 0);
      this.router.navigate(['/wyniki-wyszukiwania'], {
        queryParams: {
          query: this.mainQuery || null,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  setSearchBarStatus(opened: boolean): void {
    this.isOpened.emit(opened);
  }
}
