<ng-container
  *ngFor="let component of mainPage?.components"
  [ngSwitch]="component.__component"
>
  <app-ad-video
    *ngSwitchCase="components.AdVideo"
    [component]="component"
  ></app-ad-video>
</ng-container>
<div *ngFor="let component of mainPage?.components">
  <div [ngSwitch]="component.__component">
    <app-ad
      *ngSwitchCase="components.Ad"
      [component]="component"
      [categories]="null"
    ></app-ad>
    <app-hot-article-list
      *ngSwitchCase="components.HotArticles"
      [component]="component"
    ></app-hot-article-list>
    <app-top-article-list
      *ngSwitchCase="components.TopArticles"
      [component]="component"
    ></app-top-article-list>
    <app-good-to-know-list
      *ngSwitchCase="components.GoodToKnowArticles"
      [component]="component"
    ></app-good-to-know-list>
    <app-promoted-book-list
      *ngSwitchCase="components.PromotedBooks"
      [component]="component"
    ></app-promoted-book-list>
    <app-microservice-list
      *ngSwitchCase="components.Microservices"
      [component]="component"
    ></app-microservice-list>
    <app-newsletter
      *ngSwitchCase="components.Newsletter"
      [component]="component"
    ></app-newsletter>
    <app-bookstore
      *ngSwitchCase="components.Bookstore"
      [component]="component"
    ></app-bookstore>
    <app-partner-list
      *ngSwitchCase="components.Partners"
      [component]="component"
      [isFull]="true"
    ></app-partner-list>
  </div>
</div>
<app-large-footer *ngIf="mainPage?.footerLarge"></app-large-footer>
