import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  Category,
  CategoryTree,
  CategoryTreeNode,
  StrapiCategory,
  UrlUtils,
} from '@nursing/pwn-cms-model/lib';
import { map } from 'rxjs/operators';
import { TreeNode } from 'primeng/api';
import { environment } from '@env/environment';
import { TransferState, makeStateKey } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  categories = new BehaviorSubject([]);
  initialized = false;
  categoryTree: CategoryTree = null;
  treeNodes: TreeNode[] = [];
  private readonly tenantId = environment.tenantId;

  constructor(private http: HttpClient, private state: TransferState) {}

  getCategories(): Observable<Category[]> {
    if (!this.initialized) {
      this.refreshCategories();
      this.initialized = true;
    }
    return this.categories;
  }

  getCategoryById(categoryId: string): Observable<StrapiCategory> {
    type T = StrapiCategory;
    const key = makeStateKey<T>('categoryService_byID_' + categoryId);
    const value: T = this.state.get<T>(key, null);
    if (value) {
      this.state.set<T>(key, null);
      return of(value);
    }

    return this.http
      .get<T>(`/strapi-proxy/${this.tenantId}/category/${categoryId}`)
      .pipe(
        map((x) => {
          this.state.set<T>(key, x);
          return x;
        })
      );
  }

  getSlugId(category: StrapiCategory | Category): string {
    return UrlUtils.toSlugId(category.displayName, category.id);
  }

  refreshCategories() {
    const key = makeStateKey<Category[]>('categoryService_all');
    const value: Category[] = this.state.get<Category[]>(key, null);
    if (value) {
      this.categoryTree = new CategoryTree(value);
      this.buildTree(value);
      this.categories.next(value);
    } else {
      this.http
        .get<Category[]>(`/strapi-proxy/${this.tenantId}/category`)
        .subscribe((categories: Category[]) => {
          this.state.set<Category[]>(key, categories);
          this.categoryTree = new CategoryTree(categories);
          this.buildTree(categories);
          this.categories.next(categories);
        });
    }
  }

  getNavigationTree(categoryId: string): Observable<CategoryTree> {
    return this.getCategories().pipe(
      map((categories) => {
        if (categories == null || categories.length === 0) {
          return null;
        }

        const tree = new CategoryTree([]);
        this.categoryTree.rootNodes.forEach((treeNode) => {
          if (treeNode.isAncestorOf(categoryId)) {
            tree.rootNodes.push(treeNode);
          }
        });
        return tree;
      })
    );
  }

  getTreeNodes(categoryId: string): Observable<TreeNode[]> {
    return this.getCategories().pipe(
      map((categories) => {
        if (categories == null || categories.length === 0) {
          return null;
        }
        let currentSubTree: TreeNode[] = [];
        this.treeNodes.forEach((treeNode) => {
          if (
            treeNode.data.id === categoryId ||
            this.isAncestor(categoryId, treeNode)
          ) {
            if (treeNode.parent == null) {
              const newTreeNode = {
                data: treeNode.data,
                label: 'Wszystkie',
                leaf: true,
              };
              currentSubTree.push(newTreeNode);
              currentSubTree = currentSubTree.concat(treeNode.children);
            } else {
              currentSubTree.push(treeNode);
            }
          }
        });
        return currentSubTree;
      })
    );
  }

  private buildTree(categories: Category[]) {
    this.categoryTree = new CategoryTree([]);

    const categoryMap: Map<string, Category> = new Map<string, Category>();
    categories.forEach((category) => categoryMap.set(category.id, category));

    let sortedIds: string[] = [];
    categories.forEach((category) => sortedIds.push(category.id));
    sortedIds = this.sortIdsByOrder(sortedIds, categoryMap);

    sortedIds.forEach((categoryId) => {
      if (categoryMap.get(categoryId).parentCategory == null) {
        this.treeNodes.push(
          this.buildTreeNode(categoryMap.get(categoryId), null, categoryMap)
        );
        this.categoryTree.rootNodes.push(
          this.buildNode(categoryMap.get(categoryId), null, categoryMap)
        );
      }
    });
  }

  private buildNode(
    category: Category,
    parentNode: CategoryTreeNode,
    all: Map<string, Category>
  ): CategoryTreeNode {
    const node = new CategoryTreeNode();
    node.element = category;
    node.parent = parentNode;
    if (
      category.childCategories != null &&
      category.childCategories.length > 0
    ) {
      this.sortIdsByOrder(category.childCategories, all).forEach((childId) => {
        node.children.push(this.buildNode(all.get(childId), node, all));
      });
    }
    return node;
  }

  private buildTreeNode(
    category: Category,
    parentNode: TreeNode,
    all: Map<string, Category>
  ): TreeNode {
    const node = {};
    node['data'] = category;
    node['label'] = category.shortName
      ? category.shortName
      : category.displayName;
    node['parent'] = parentNode;
    if (parentNode === null || category.alwaysExpanded === true) {
      node['expanded'] = true;
    } else {
      node['expanded'] = false;
    }
    node['children'] = [];

    if (
      category.childCategories != null &&
      category.childCategories.length > 0
    ) {
      this.sortIdsByOrder(category.childCategories, all).forEach((childId) => {
        node['children'].push(this.buildTreeNode(all.get(childId), node, all));
      });
    } else {
      node['leaf'] = true;
    }
    return node;
  }

  private sortIdsByOrder(ids: string[], all: Map<string, Category>): string[] {
    return ids.sort((id1, id2) => {
      return all.get(id1).order > all.get(id2).order ? 1 : -1;
    });
  }

  private isAncestor(categoryId: string, treeNode: TreeNode): boolean {
    return this.find(categoryId, treeNode).length > 0;
  }

  private find(categoryId: string, currentTreeNode: TreeNode): TreeNode[] {
    const result: TreeNode[] = [];

    if (currentTreeNode.data.id === categoryId) {
      currentTreeNode.expanded = true;
      result.push(currentTreeNode);
    }

    result.push(
      ...currentTreeNode.children.flatMap((treeNode) =>
        this.find(categoryId, treeNode)
      )
    );
    return result;
  }
}
