<div
  class="header-container"
  [ngStyle]="{
    backgroundImage: fullImage ? 'none' : 'url(' + backgroundImageUrl + ')'
  }"
>
  <div class="left-container">
    <p *ngIf="!book" class="title">{{ title }}</p>
    <p *ngIf="book" class="title">Artykuły</p>
    <p *ngIf="!book" class="subtitle">{{ subtitle | uppercase }}</p>
    <p *ngIf="book && book.isMagazine" class="source-subtitle">
      z magazynu “{{ book?.title }}”
    </p>
    <p *ngIf="book && !book.isMagazine" class="source-subtitle">
      z książki “{{ book?.title }}”
    </p>
  </div>
  <div class="right-container">
    <img
      *ngIf="fullImage"
      [strapiImage]="fullImage"
      class="header-image-full"
      [alt]="title"
    />
    <img
      *ngIf="sideImage"
      [strapiImage]="sideImage"
      class="header-image-side"
      [ngStyle]="sideImageStyle"
      [alt]="title"
    />
  </div>
  <img
    *ngIf="mobileImage"
    [strapiImage]="mobileImage"
    class="mobile-image"
    [alt]="title"
  />
</div>
