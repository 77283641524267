import { Component, Input } from '@angular/core';
import { Author } from '@nursing/pwn-cms-model/lib';

@Component({
  selector: 'app-article-author-info-list',
  templateUrl: './article-author-info-list.component.html',
  styleUrls: [
    './article-author-info-list.component.scss',
    './article-author-info-list.component.mobile.scss',
  ],
})
export class ArticleAuthorInfoListComponent {
  @Input()
  authors: Author[];

  constructor() {}
}
