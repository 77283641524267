import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageService } from '@app/@shared/service/page/page.service';
import { Subscription } from 'rxjs';
import {
  StrapiComponentTypeEnum,
  StrapiMainPage,
} from '@nursing/pwn-cms-model/lib';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  isLoading = false;

  mainPageSubscription: Subscription;
  mainPage: StrapiMainPage;

  components: typeof StrapiComponentTypeEnum = StrapiComponentTypeEnum;

  constructor(public pageService: PageService) {}

  ngOnInit() {
    this.isLoading = true;

    this.mainPageSubscription = this.pageService
      .getMainPage()
      .subscribe((mainPage) => {
        this.mainPage = mainPage;
      });
  }

  ngOnDestroy(): void {
    this.mainPageSubscription?.unsubscribe();
  }
}
