import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { concatMap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, forkJoin, Observable } from 'rxjs';
import { Article, PwnUser } from '@nursing/pwn-cms-model/lib';

import { AuthenticationService } from '@app/@shared/service/authentication/authentication.service';
import { ArticleService } from '@app/@shared/service/article/article.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class UserProductService {
  public refreshFavorites = new BehaviorSubject<boolean>(false);
  public refreshFavorites$ = this.refreshFavorites.asObservable();

  private userFavoriteArticlesIdsSubject = new BehaviorSubject<string[]>([]);
  public _userFavoriteArticlesIds = this.userFavoriteArticlesIdsSubject.asObservable();

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private articleService: ArticleService
  ) {
    this.authService.pwnUserWithTokens$.subscribe((res) => {
      if (res) {
        this.updateUserFavoriteArticlesIds(res.user.favoriteProducts);
      } else {
        this.updateUserFavoriteArticlesIds([]);
      }
    });
  }

  updateUserFavoriteArticlesIds(articleIds: string[]) {
    this.userFavoriteArticlesIdsSubject.next(articleIds);
  }

  getLastViewedArticles(): Observable<Article[]> {
    return this.getUserLastViewedArticles(
      environment.tenantId,
      this.authService.credentials.email
    );
  }

  getUserLastViewedArticles(
    tenantId: string,
    email: string
  ): Observable<Article[]> {
    return this.http.get<PwnUser>(`/pwn-user/user/${tenantId}/${email}`).pipe(
      concatMap((res) => {
        if (res.viewedProducts) {
          const articles: Observable<
            Article
          >[] = res.viewedProducts.map((articleId: string) =>
            this.articleService.getArticle(articleId)
          );
          articles.reverse();
          return forkJoin(articles);
        } else {
          return EMPTY;
        }
      })
    );
  }

  addArticleToViewed(articleId: string): Observable<PwnUser> {
    return this.addUserArticleToViewed(
      this.authService.credentials.tenantId,
      this.authService.credentials.email,
      articleId
    );
  }

  addUserArticleToViewed(
    tenantId: string,
    email: string,
    articleId: string
  ): Observable<PwnUser> {
    return this.http.put<PwnUser>(
      `/pwn-user/user-product/${tenantId}/${email}/add-viewed/${articleId}`,
      {
        id: articleId,
      }
    );
  }

  addArticleToFavorite(articleId: string): Observable<PwnUser> {
    return this.addUserArticleToFavorite(
      this.authService.credentials.tenantId,
      this.authService.credentials.email,
      articleId
    );
  }

  addUserArticleToFavorite(tenantId: string, email: string, articleId: string) {
    return this.http.put<PwnUser>(
      `/pwn-user/user-product/${tenantId}/${email}/add-favorite/${articleId}`,
      {
        id: articleId,
      }
    );
  }

  removeArticleFromFavorite(articleId: string): Observable<PwnUser> {
    return this.removeUserArticleFromFavorite(
      this.authService.credentials.tenantId,
      this.authService.credentials.email,
      articleId
    );
  }

  removeUserArticleFromFavorite(
    tenantId: string,
    email: string,
    articleId: string
  ) {
    return this.http.put<PwnUser>(
      `/pwn-user/user-product/${tenantId}/${email}/delete-favorite/${articleId}`,
      {
        id: articleId,
      }
    );
  }

  getFavoriteArticles(): Observable<Article[]> {
    if (this.authService.credentials) {
      return this.getUserFavoriteArticles(
        environment.tenantId,
        this.authService.credentials.email
      );
    } else {
      return EMPTY;
    }
  }

  getUserFavoriteArticles(
    tenantId: string,
    email: string
  ): Observable<Article[]> {
    return this.http.get<PwnUser>(`/pwn-user/user/${tenantId}/${email}`).pipe(
      concatMap((res) => {
        if (res.favoriteProducts) {
          const articles: Observable<
            Article
          >[] = res.favoriteProducts.map((articleId: string) =>
            this.articleService.getArticle(articleId)
          );
          articles.reverse();
          return forkJoin(articles);
        } else {
          return EMPTY;
        }
      })
    );
  }
}
