import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CategoryTree,
  StrapiArticleListHeaderComponent,
  StrapiAuthorListHeaderComponent,
  StrapiBook,
  StrapiBookTypeEnum,
  StrapiComponentTypeEnum,
  StrapiImage,
  StrapiImageClass,
} from '@nursing/pwn-cms-model/lib';
import { environment } from '@env/environment';
import { StrapiListingPageDisplayTypeEnum } from '@nursing/pwn-cms-model/lib/model/strapi/enums/strapi-listing-page-display-type-enum';
import { CategoryService } from '@app/@shared/service/category/category.service';
import { Subscription } from 'rxjs';
import { BookService } from '@shared/service/book/book.service';
import { BreadcrumbService } from '@app/@shared/service/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './header.component.mobile.scss'],
})
export class HeaderComponent implements OnDestroy {
  readonly tenantId = environment.tenantId;
  @Input()
  set component(
    value: StrapiArticleListHeaderComponent | StrapiAuthorListHeaderComponent
  ) {
    this._component = value;
    this.initialize();
  }
  _component:
    | StrapiArticleListHeaderComponent
    | StrapiAuthorListHeaderComponent;

  @Input() set inSearchResults(val: boolean) {
    if (val) {
      this.title = 'Wyniki wyszukiwania';
      if (this._component.backgroundImage) {
        this.backgroundImageUrl = new StrapiImageClass(
          this._component.backgroundImage
        ).getPublicImageUrl(
          environment.serverUrl + '/strapi-proxy/' + this.tenantId
        );
        this.backgroundImage = this._component.backgroundImage;
      }
    }
  }

  @Input()
  set sourceId(val: string) {
    this.bookService.getBookById(val).subscribe((book: StrapiBook) => {
      this.book = {
        id: book.id,
        image: book.images[0],
        title: book.title,
        year: book.year,
        isMagazine: book.type === StrapiBookTypeEnum.magazine,
      };
      this.sideImage = this.book.image;
      const maxHeight = 192;
      const height = book.images[0].height;
      let width = book.images[0].width;

      if (height > maxHeight) {
        width *= maxHeight / height;
      }
      this.sideImageStyle = {
        'max-height': maxHeight + 'px',
        right: `calc(min(0px, max(0px - (${width}px / 2), (1100px - 100vw) / 4)))`,
      };

      this.backgroundImageUrl = new StrapiImageClass(
        this._component.backgroundImage
      ).getPublicImageUrl(
        environment.serverUrl + '/strapi-proxy/' + this.tenantId
      );
      this.backgroundImage = this._component.backgroundImage;
    });
  }

  @Input()
  set id(value: string) {
    this.treeSubscription = this.categoryService
      .getNavigationTree(value)
      .subscribe((tree) => {
        this.initialize(tree, value);
      });
  }

  book:
    | {
        id: string;
        image: StrapiImage;
        title: string;
        year: number;
        isMagazine: boolean;
      }
    | undefined;

  title: string;
  subtitle: string | undefined;

  backgroundImage: StrapiImage;
  backgroundImageUrl: string;
  fullImage: StrapiImage;
  sideImage: StrapiImage;
  mobileImage: StrapiImage;

  sideImageStyle = {};

  categorySubscription: Subscription;
  treeSubscription: Subscription;

  constructor(
    private categoryService: CategoryService,
    private bookService: BookService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnDestroy() {
    this.categorySubscription?.unsubscribe();
    this.treeSubscription?.unsubscribe();
  }

  private initialize(tree?: CategoryTree, categoryId?: string) {
    const categoryNode = tree?.findOne(categoryId);
    const category = categoryNode?.element;

    if (category) {
      this.breadcrumbService.category = categoryNode;

      if (category.parentCategory) {
        const rootCategory = categoryNode.getRoot().element;

        this.title = rootCategory.displayName;
        this.subtitle = category.displayName;
      } else {
        this.title = category.displayName;
        this.subtitle = undefined;
      }
    } else if (
      this._component.__component === StrapiComponentTypeEnum.AuthorListHeader
    ) {
      this.title = (this._component as StrapiAuthorListHeaderComponent).title;
    }

    if (category?.mobileImage) {
      this.mobileImage = category.mobileImage;
    } else {
      this.mobileImage = undefined;
    }

    if (category?.fullImg) {
      if (
        category.listingPageDisplayType ===
        StrapiListingPageDisplayTypeEnum.full
      ) {
        this.fullImage = category.fullImg;
        this.sideImage = undefined;
      } else {
        this.fullImage = undefined;
        this.sideImage = category.fullImg;

        const maxHeight = 192;
        const height = category.fullImg.height;
        let width = category.fullImg.width;

        if (height > maxHeight) {
          width *= maxHeight / height;
        }

        this.sideImageStyle = {
          'max-height': maxHeight + 'px',
          right: `calc(min(0px, max(0px - (${width}px / 2), (1100px - 100vw) / 4)))`,
        };
      }
    } else {
      this.fullImage = undefined;
      this.sideImage = undefined;
    }

    if (this._component.backgroundImage) {
      this.backgroundImageUrl = new StrapiImageClass(
        this._component.backgroundImage
      ).getPublicImageUrl(
        environment.serverUrl + '/strapi-proxy/' + this.tenantId
      );
      if (
        this._component.__component === StrapiComponentTypeEnum.AuthorListHeader
      ) {
        this.fullImage = this.backgroundImage;
        this.mobileImage = this.backgroundImage;
      } else {
        this.backgroundImage = this.backgroundImage;
      }
    }
  }
}
