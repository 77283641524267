import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MicroserviceListComponent } from '@app/microservice-list/microservice-list.component';
import { MicroserviceComponent } from './microservice/microservice.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';

@NgModule({
  declarations: [MicroserviceListComponent, MicroserviceComponent],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [MicroserviceComponent, MicroserviceListComponent],
})
export class MicroserviceListModule {}
