import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  StrapiBook,
  StrapiBookstoreComponent,
  StrapiCategory,
  StrapiImageClass,
  StrapiImageSizeEnum,
} from '@nursing/pwn-cms-model/lib';
import { environment } from '@env/environment';
import { BookService } from '@shared/service/book/book.service';
import { CategoryService } from '@shared/service/category/category.service';
import { AuthorService } from '@shared/service/author/author.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { PlatformService } from '@shared/service/ssr/platform.service';

@Component({
  selector: 'app-bookstore',
  templateUrl: './bookstore.component.html',
  styleUrls: [
    './bookstore.component.scss',
    './bookstore.component.mobile.scss',
  ],
})
export class BookstoreComponent implements OnInit, OnDestroy {
  readonly tenantId = environment.tenantId;
  booksAuthors: Map<string, string> = new Map<string, string>();

  @Input()
  component: StrapiBookstoreComponent;

  @Input()
  set categoryId(value: string) {
    if (value) {
      this._categoryId = value;
    }
  }

  _categoryId: string;

  initialized = false;
  logoSrc: string;
  books: StrapiBook[] = [];
  finalBooks: StrapiBook[] = [];
  booksImagesSrc: Map<string, string> = new Map<string, string>();
  public imageFormat = StrapiImageSizeEnum.small;

  categorySubscription: Subscription;
  bookSubscription: Subscription;

  constructor(
    private bookService: BookService,
    private categoryService: CategoryService,
    private authorService: AuthorService,
    private readonly platformService: PlatformService
  ) {}

  ngOnInit(): void {
    this._categoryId
      ? this.getPromotedBooks(this._categoryId)
      : this.getPromotedBooks();
  }

  getPromotedBooks(categoryId?: string): void {
    this.books = [];
    this.books = this.books.concat(this.component.books);

    if (categoryId && this.books.length < this.component.booksToDisplay) {
      this.categorySubscription = this.categoryService
        .getCategoryById(categoryId)
        .subscribe((res: StrapiCategory) => {
          if (res.promotedBooks?.length > 0) {
            if (
              res.promotedBooks.length + this.books.length >
              this.component.booksToDisplay
            ) {
              res.promotedBooks = res.promotedBooks.slice(
                0,
                this.component.booksToDisplay - this.books.length
              );
            }
            this.removeDuplicatesAndSliceData(res.promotedBooks);
          }
          if (this.books.length < this.component.booksToDisplay) {
            this.getTopBooks();
          }
        });
    } else if (
      !categoryId &&
      this.books.length < this.component.booksToDisplay
    ) {
      this.getTopBooks();
    } else {
      this.prepareLogoAndBookAuthors();
    }
  }

  removeDuplicatesAndSliceData(data: StrapiBook[]): void {
    this.books = this.books.concat(data);
    this.books = _.uniqBy(this.books, (element) => {
      return element.id;
    }).slice(0, this.component.booksToDisplay);
  }

  getTopBooks(): void {
    this.bookSubscription = this.bookService
      .getTopBooks(this.component.booksToDisplay)
      .subscribe((result: StrapiBook[]) => {
        this.removeDuplicatesAndSliceData(result);
        this.prepareLogoAndBookAuthors();
      });
  }

  prepareLogoAndBookAuthors() {
    this.books.forEach((book: StrapiBook) => {
      this.authorService.getAuthorsByBook(book.id).subscribe((authors) => {
        let bookAuthors = '';
        for (let i = 0; i < authors.length; i++) {
          bookAuthors += authors[i].name;
          if (i !== authors.length - 1) {
            bookAuthors += ', ';
          }
        }
        this.booksAuthors.set(book.id, bookAuthors);
        this.initialized = true;
      });
    });

    if (this.component.logo != null) {
      this.logoSrc = new StrapiImageClass(
        this.component.logo
      ).getPublicImageUrl(
        environment.serverUrl + '/strapi-proxy/' + this.tenantId
      );
    }
  }

  ngOnDestroy(): void {
    this.categorySubscription?.unsubscribe();
    this.bookSubscription?.unsubscribe();
  }

  isBrowser(): boolean {
    return PlatformService.isBrowser;
  }
}
