import { Component, Input } from '@angular/core';
import {
  Article,
  StrapiComponentTypeEnum,
  StrapiImageSizeEnum,
} from '@nursing/pwn-cms-model/lib';

import { environment } from '@env/environment';

@Component({
  selector: 'app-article-body',
  templateUrl: './article-body.component.html',
  styleUrls: [
    './article-body.component.scss',
    './article-body.component.mobile.scss',
  ],
})
export class ArticleBodyComponent {
  readonly tenantId = environment.tenantId;
  componentTypes: typeof StrapiComponentTypeEnum = StrapiComponentTypeEnum;
  public imageFormat = StrapiImageSizeEnum.small;

  @Input()
  article: Article;

  constructor() {}

  getServerUrl(): string {
    return environment.serverUrl;
  }
}
