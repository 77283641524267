<div *ngIf="!zoneLoaded" class="ad-loader"></div>
<div #mobileChild>
  <div
    *ngIf="component && isBrowser() && (showDesktop || showMobile)"
    class="ad-layer"
  >
    <div
      *ngIf="showDesktop"
      class="desktop ad-container"
      [innerHtml]="desktopUrl"
    ></div>
    <div
      *ngIf="showMobile"
      class="mobile ad-container"
      [innerHtml]="mobileUrl"
    ></div>
  </div>
</div>
