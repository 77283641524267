import { Component, Input } from '@angular/core';
import {
  Author,
  StrapiImageSizeEnum,
  UrlUtils,
} from '@nursing/pwn-cms-model/lib';
import { environment } from '@env/environment';

@Component({
  selector: 'app-article-author-info',
  templateUrl: './article-author-info.component.html',
  styleUrls: [
    './article-author-info.component.scss',
    './article-author-info.component.mobile.scss',
  ],
})
export class ArticleAuthorInfoComponent {
  readonly tenantId = environment.tenantId;
  @Input() set component(value: Author) {
    this.author = value;
    this.authorSlug = UrlUtils.toSlugId(value.name, value.id);
  }

  public author: Author;
  public authorSlug = '';
  public imageFormat = StrapiImageSizeEnum.thumbnail;

  constructor() {}

  getServerUrl(): string {
    return environment.serverUrl;
  }
}
