import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import {
  DomSanitizer,
  makeStateKey,
  Meta,
  SafeHtml,
  Title,
  TransferState,
} from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { environment } from '@env/environment';
import { Logger } from '@core';
import { AuthenticationService } from '@shared/service/authentication/authentication.service';
import { ConfigurationService } from '@shared/service/configuration/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { StrapiConfiguration, StrapiTheme } from '@nursing/pwn-cms-model/lib';
import { PlatformService } from '@shared/service/ssr/platform.service';
import { WindowService } from '@shared/service/ssr/window.service';
import { SassHelperComponent } from '@shared/providers/sass-helper/sass-helper.component';
import { PrimeNGConfig } from 'primeng/api';
import { MetaDataService } from './@shared/service/meta-data-service/meta-data.service';

const log = new Logger('AppComponent');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', './app.component.mobile.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  showCookiesPopup = true;

  cookiePolicyContent: SafeHtml;

  @ViewChild(SassHelperComponent, { static: true })
  private sassHelper: SassHelperComponent;

  CONFIG_STATE_KEY = makeStateKey<SafeHtml>('config');

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private configurationService: ConfigurationService,
    private cookieService: CookieService,
    private domSanitizer: DomSanitizer,
    private windowService: WindowService,
    private state: TransferState,
    private metaDataService: MetaDataService,
    private primeConfig: PrimeNGConfig
  ) {}

  ngOnInit() {
    if (!PlatformService.isBrowser) {
      this.showCookiesPopup = false;
    } else {
      this.loadStyles();
    }
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }
    log.debug('App component started...');
    this.authService.refreshToken().subscribe();

    this.cookiePolicyContent = this.state.get<SafeHtml>(
      this.CONFIG_STATE_KEY,
      null as SafeHtml
    ) as SafeHtml;

    if (!this.cookiePolicyContent) {
      this.configurationService
        .getConfiguration()
        .subscribe((config: StrapiConfiguration) => {
          if (config) {
            this.cookiePolicyContent = this.domSanitizer.bypassSecurityTrustHtml(
              config.cookiesPolicy
            );
          }
        });
    }

    if (PlatformService.isBrowser) {
      log.debug('Browser platform started...');

      // this.metaDataService.setData('this.defaultConfigTitle', 'this.defaultConfigDesc', 'this.defaultConfigLogoUrl', 'wp.pl', MetaOgType.Website);
      this.metaDataService.setDefaultMetaData();

      const cookie = this.cookieService.get('nursing-accept-cookies');
      if (cookie) {
        this.showCookiesPopup = false;
      }

      this.router.events.subscribe((event) => {
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        this.windowService.scrollTo(0, 0);
      });

      let node = document.createElement('script');
      node.textContent =
        '(function (w, d, s, l, i) {' +
        'w[l] = w[l] || [];' +
        'w[l].push({' +
        '"gtm.start": new Date().getTime(),' +
        'event: "gtm.js",' +
        '});' +
        'var f = d.getElementsByTagName(s)[0],' +
        'j = d.createElement(s),' +
        'dl = l != "dataLayer" ? "&l=" + l : "";' +
        'j.async = true;' +
        'j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;' +
        'f.parentNode.insertBefore(j, f);' +
        '})(window, document, "script", "dataLayer", "GTM-T6NMXQV");';
      node.async = true;
      document.head.appendChild(node);

      node = document.createElement('script');
      node.src = 'https://cdn.embedly.com/widgets/platform.js';
      node.type = 'text/javascript';
      node.async = true;
      node.defer = true;
      document.head.appendChild(node);

      node = document.createElement('script');
      node.textContent =
        "function onSyneriseLoad() { SR.init({'trackerKey':'d6a47c93-3916-47f6-87b9-fb7d83f45bc5', 'trackingDomain': 'https://apima.nursing.pwn.pl' });} " +
        "(function(s,y,n,e,r,i,se){s['SyneriseObjectNamespace']=r;s[r]=s[r]||[], s[r]._t=1*new Date(),s[r]._i=0,s[r]._l=i;var z=y.createElement(n), se=y.getElementsByTagName(n)[0];z.async=1;z.src=e;se.parentNode.insertBefore(z,se); z.onload=z.onreadystatechange=function(){var rdy=z.readyState; if(!rdy||/complete|loaded/.test(z.readyState)){s[i]();z.onload = null; z.onreadystatechange=null;}};})(window,document,'script', '//apima.nursing.pwn.pl/dswtl83z0.js','SR', 'onSyneriseLoad');";
      node.async = true;
      document.head.appendChild(node);

      node = document.createElement('script');
      node.src = 'https://script.crazyegg.com/pages/scripts/0088/6752.js';
      node.type = 'text/javascript';
      node.async = true;
      node.defer = true;
      document.head.appendChild(node);

      // Generated by Revive Adserver v4.0.0
      node = document.createElement('script');
      node.src = 'https://r2.pwn.net.pl/www/delivery/spcjs.php?id=1';
      node.type = 'text/javascript';
      node.async = true;
      node.defer = true;
      document.head.appendChild(node);
    } else {
      log.debug('Server platform started...');
    }
    this.setPrimeNgConfig();
  }

  setPrimeNgConfig(): void {
    this.primeConfig.setTranslation({
      firstDayOfWeek: 0,
      dayNames: [
        'Niedziela',
        'Poniedziałek',
        'Wtorek',
        'Środa',
        'Czwartek',
        'Piątek',
        'Sobota',
      ],
      dayNamesShort: ['NIEDZ.', 'PON.', 'WT.', 'ŚR.', 'CZW.', 'PT.', 'SOB.'],
      dayNamesMin: ['NIEDZ.', 'PON.', 'WT.', 'ŚR.', 'CZW.', 'PT.', 'SOB.'],
      monthNames: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień',
      ],
      monthNamesShort: [
        'Sty',
        'Lut',
        'Mar',
        'Kwi',
        'Maj',
        'Cze',
        'Lip',
        'Sie',
        'Wrz',
        'Paź',
        'Lis',
        'Grud',
      ],
      today: 'Dzisiaj',
      clear: 'Wczyść',
      dateFormat: 'yy-mm-dd',
      weekHeader: 'Tydz',
    });
  }

  acceptCookies() {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    this.cookieService.set('nursing-accept-cookies', 'true', date);
    this.showCookiesPopup = false;
  }

  loadStyles(): void {
    this.configurationService.getTheme().subscribe((res: StrapiTheme) => {
      if (res && res.style) {
        const { __id, id, createdAt, updatedAt, __v, ...rest } = res.style;
        const colorMap = new Map<string, string>(Object.entries(rest));
        colorMap.delete('_id');
        colorMap.delete('updated_by');
        colorMap.delete('created_by');

        colorMap.forEach((value, key) => {
          this.sassHelper.setProperty(key, value);
        });

        if (colorMap.size > 0) {
          this.sassHelper.setModifiedProperties(colorMap);
        }
      }
    });
  }

  ngOnDestroy() {}
}
