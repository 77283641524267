<div class="video-container" #videoPlayer *ngIf="isPlayerVisible">
  <ng-container *ngIf="isPlayerVisible">
    <meta name="robots" />
  </ng-container>
  <p (click)="redirectToLink()" class="notification-href">
    Przejdź do księgarni
    <i class="fas fa-arrow-right" style="line-height: 1; margin-left: 5px;"></i>
  </p>
  <video
    class="backgroundVideo"
    (fullscreenchange)="handleFullscreenChange($event)"
    #videoElement
    controls="controls"
    controlsList="nodownload"
    alt=""
    preload="auto"
    playsinline=""
    webkit-playsinline=""
    x5-playsinline=""
    height="100%"
    width="90%"
  >
    <source [src]="videoUrl" type="video/mp4" />
  </video>
  <button class="playerButton" (click)="closePlayerBtn()">X</button>
</div>
