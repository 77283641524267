import { Component, Input, OnInit } from '@angular/core';
import {
  StrapiGalleryComponent,
  StrapiImageClass,
  StrapiImageComponent,
  StrapiImageSizeEnum,
} from '@nursing/pwn-cms-model/lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@env/environment';
import { GalleryImage } from '@shared/model/image-gallery/gallery-image';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: [
    './image-gallery.component.scss',
    './image-gallery.component.mobile.scss',
  ],
})
export class ImageGalleryComponent implements OnInit {
  readonly tenantId = environment.tenantId;
  @Input() component: StrapiGalleryComponent;

  images: StrapiImageComponent[] = [];

  imagesSrc: GalleryImage[] = [];

  page = 0;
  showIndicators: boolean;
  showFullImage: boolean;
  modalReference: any;
  public imageFormat = StrapiImageSizeEnum.small;

  constructor(private modal: NgbModal) {}

  ngOnInit(): void {
    this.showFullImage = false;
    this.images = this.component.images;
    this.component.images?.forEach((image: StrapiImageComponent) => {
      this.imagesSrc.push(
        new GalleryImage(
          new StrapiImageClass(image.img).getPublicImageUrl(
            environment.serverUrl + '/strapi-proxy/' + this.tenantId
          ),
          image.title,
          image.description,
          image.link
        )
      );
    });
    window.innerWidth > 1000
      ? (this.showIndicators = true)
      : (this.showIndicators = false);
  }

  openGallery(content: any): void {
    this.showFullImage = false;
    this.modalReference = this.modal.open(content, {
      centered: true,
      windowClass: 'image-gallery',
    });
  }

  closeModal() {
    this.modalReference.close();
  }

  toggleSize() {
    this.showFullImage = !this.showFullImage;
  }
}
