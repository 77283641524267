import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StrapiMainMenu } from '@nursing/pwn-cms-model/lib';
import { environment } from '@env/environment';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { PlatformService } from '../ssr/platform.service';
@Injectable({
  providedIn: 'root',
})
export class MainMenuService {
  timeout = false;
  private readonly tenantId = environment.tenantId;

  constructor(private http: HttpClient, private state: TransferState) {}

  getMenuElements(): Observable<StrapiMainMenu> {
    type T = StrapiMainMenu;
    const key = makeStateKey<T>('mainMenuServiceService_elements');
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (PlatformService.isBrowser && !this.timeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.timeout = false;
        }, 2000);
        this.timeout = true;
      }
      return of(value);
    }
    // nie ma w cache - odczytujemy i dodajemy do cache
    return this.http.get<T>(`/strapi-proxy/${this.tenantId}/main-menu`).pipe(
      map((x) => {
        this.state.set<T>(key, x);
        return x;
      })
    );
  }
}
