<ng-container
  *ngFor="let component of components"
  [ngSwitch]="component.__component"
>
  <app-ad-video
    *ngSwitchCase="StrapiComponentTypeEnum.AdVideo"
    [component]="component"
  ></app-ad-video>
</ng-container>
<div *ngFor="let component of components" [ngSwitch]="component.__component">
  <app-ad
    *ngSwitchCase="StrapiComponentTypeEnum.Ad"
    [component]="component"
    [categories]="article?.categories"
  ></app-ad>
  <app-article-header
    *ngSwitchCase="StrapiComponentTypeEnum.ArticleHeader"
    [article]="article"
  ></app-article-header>
</div>
