import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  StrapiComponentTypeEnum,
  StrapiEventPartnersComponent,
  StrapiPartner,
  StrapiEvent,
  StrapiPartnersComponent,
  StrapiPartnersStyleEnum,
} from '@nursing/pwn-cms-model/lib';
import { Observable, Subscription } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { tap } from 'rxjs/operators';

import { PartnerService } from '@app/@shared/service/partner/partner.service';
import { PlatformService } from '@shared/service/ssr/platform.service';

@Component({
  selector: 'app-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: [
    './partner-list.component.scss',
    './partner-list.component.mobile.scss',
  ],
})
export class PartnerListComponent implements OnInit, OnDestroy {
  @Input()
  component: StrapiPartnersComponent | StrapiEventPartnersComponent;

  @Input()
  set event(value: StrapiEvent) {
    this.partners = value?.partners ?? [];
  }

  @Input()
  isFull = false;

  @Input()
  isMain = false;

  partners: StrapiPartner[] = [];
  displayTypeEnum: typeof StrapiPartnersStyleEnum = StrapiPartnersStyleEnum;
  isBrowserDisplay: boolean;
  displayMode: string;

  partners$: Subscription;
  resize$: Subscription;

  constructor(
    private partnerService: PartnerService,
    private readonly platformService: PlatformService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.isBrowserDisplay = this.isBrowser();
    this.resize$ = this.onWindowBreakPoints(['(max-width: 576px)']).subscribe();
    if (this.component.__component === StrapiComponentTypeEnum.Partners) {
      this.partners$ = this.partnerService
        .getPartnerList()
        .subscribe((data) => {
          this.partners = data;
        });
    }
  }

  isBrowser(): boolean {
    return PlatformService.isBrowser;
  }

  onWindowBreakPoints(breakPoint: string[]): Observable<BreakpointState> {
    return this.breakpointObserver.observe(breakPoint).pipe(
      tap((res) => {
        if (res.matches) {
          this.displayMode = 'vertical';
        } else {
          this.displayMode = 'horizontal';
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.partners$?.unsubscribe();
    this.resize$?.unsubscribe();
  }
}
