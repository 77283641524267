<div class="search-bar-container" *ngIf="!hiddenMode">
  <form (submit)="onSearch(null, searchInput)">
    <div class="search-bar">
      <input
        #searchInput
        [(ngModel)]="mainQuery"
        [placeholder]="inputPlaceholder"
        (focus)="focusedOnInput = true"
        (blur)="focusedOnInput = false"
        [ngModelOptions]="{ standalone: true }"
      />
      <a
        [routerLink]="['./']"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ query: mainQuery }"
      >
        <img
          *ngIf="!isExtraBarActive"
          src="assets/icons/search.png"
          alt="Wyszukiwarka"
        />
      </a>
    </div>
    <div *ngIf="isExtraBarActive" class="flex-row-container">
      <div class="search-bar extra-bar">
        <input
          [(ngModel)]="workplaceQuery"
          [placeholder]="inputExtraBarPlaceholder"
          (focus)="focusedOnInput = true"
          (blur)="focusedOnInput = false"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
      <button
        class="search"
        *ngIf="isExtraBarActive"
        [routerLink]="['./']"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ query: mainQuery, workplace: workplaceQuery }"
      >
        Szukaj
      </button>
      <!--      </a>-->
    </div>
  </form>
  <div class="search-bar-fields">
    <div class="results">
      <span>Znaleziono wpisów: </span>
      <span class="results-count">{{ totalElements }}</span>
    </div>
    <div class="sort-types" *ngIf="!hideSearchOptions">
      <span class="sort-label">Sortuj:</span>
      <div class="sort-types-list" ngbDropdown>
        <span class="sort-options-toggle" ngbDropdownToggle>{{
          currentOption
        }}</span>
        <div ngbDropdownMenu>
          <button
            ngbDropdownItem
            *ngFor="let option of sortOptions"
            (click)="setCurrentSortOption(option, true)"
          >
            {{ option }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
