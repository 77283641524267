<div
  class="content-container"
  *ngIf="articlePage"
  [ngStyle]="{ background: background ? 'url(' + backgroundSrc + ')' : '' }"
>
  <div class="downloadmark">
    <span
      >Pobrane z: <a [href]="portalUrl" target="_blank">{{ portalName }}</a> |
    </span>
    <span
      >E-mail użytkownika: <span id="user-email">{{ userEmail }}</span> |
    </span>
    <span>Data pobrania: {{ downloadDate }}</span>
  </div>
  <ng-container *ngIf="article?.AdVideo">
    <app-ad-video [component]="article?.AdVideo"></app-ad-video>
  </ng-container>
  <div
    class="top-content-container"
    [ngStyle]="{ 'min-height': viewLoaded ? 'unset' : '100vh' }"
  >
    <app-article-top-content
      [components]="articlePage.topContent"
      [article]="article"
    ></app-article-top-content>
  </div>
  <main>
    <div class="main-content-container">
      <app-article-main-content
        [components]="articlePage.mainContent"
        [article]="article"
      ></app-article-main-content>
    </div>
    <div class="side-content-container">
      <app-article-side-content
        [components]="articlePage.sideContent"
        [article]="article"
      ></app-article-side-content>
    </div>
  </main>
  <div *ngIf="isBrowser()">
    <app-article-bottom-content
      [components]="articlePage.bottomContent"
      [category]="(article?.categories)[0]"
      [categories]="article?.categories"
    ></app-article-bottom-content>
  </div>
  <app-large-footer *ngIf="articlePage.footerLarge"></app-large-footer>
</div>
