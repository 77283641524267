import { Component, Input, OnInit } from '@angular/core';
import { StrapiSocialMediaComponent } from '@nursing/pwn-cms-model/lib';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: [
    './social-media.component.scss',
    './social-media.component.mobile.scss',
  ],
})
export class SocialMediaComponent implements OnInit {
  @Input()
  component: StrapiSocialMediaComponent;

  readonly serverUrl = environment.serverUrl;

  activeUrl = '';
  fbShareUrl = '';
  twShareUrl = '';
  liShareUrl = '';
  private readonly fbSharePath =
    'https://www.facebook.com/sharer/sharer.php?u=';
  private readonly twSharePath = 'https://twitter.com/intent/tweet?url=';
  private readonly liSharePath =
    'https://www.linkedin.com/sharing/share-offsite/?url=';

  constructor(private readonly route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.activeUrl = this.serverUrl + this.router.url;
    this.fbShareUrl = this.fbSharePath + this.activeUrl;
    this.twShareUrl = this.twSharePath + this.activeUrl;
    this.liShareUrl = this.liSharePath + this.activeUrl;
  }
}
