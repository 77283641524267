import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { GoodToKnowComponent } from '@app/good-to-know-list/good-to-know/good-to-know.component';
import { GoodToKnowListComponent } from '@app/good-to-know-list/good-to-know-list.component';
import { SharedModule } from '@app/@shared';

@NgModule({
  declarations: [GoodToKnowListComponent, GoodToKnowComponent],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [GoodToKnowComponent, GoodToKnowListComponent],
})
export class GoodToKnowListModule {}
