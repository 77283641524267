import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '@app/@shared/service/authentication/authentication.guard';
import { LoggedInGuard } from './@shared/service/authentication/logged-in.guard';
import { ArticlesComponent } from './articles/articles.component';
import { Shell } from './shell/shell.service';

const routes: Routes = [
  Shell.childRoutesNoAuth([
    { path: 'artykuly/:categoryId', component: ArticlesComponent },
  ]),
  {
    path: '',
    loadChildren: () =>
      import('./shell/shell.module').then((m) => m.ShellModule),
  },
  ///// ----- Routes not allowed for logged user ----- /////
  {
    path: 'rejestracja',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'logowanie',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('./sign-in/sign-in.module').then((m) => m.SignInModule),
  },
  {
    path: 'reset-hasla',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('./reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  ///// ----- Routes not allowed for logged user end ----- /////
  {
    path: 'produkty',
    canActivate: [AuthenticationGuard],
    loadChildren: () =>
      import('./product/product.module').then((m) => m.ProductModule),
  },
  ///// ----- Routes with auth required end ----- /////

  // Fallback route, zawsze na końcu!!!
  { path: '**', redirectTo: 'nie-znaleziono', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
