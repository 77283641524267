import { Component, Input, OnInit } from '@angular/core';
import {
  StrapiComponent,
  StrapiComponentTypeEnum,
  StrapiGalleryComponent,
  StrapiImageComponent,
  StrapiImageSizeEnum,
  StrapiParagraphComponent,
} from '@nursing/pwn-cms-model/lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import { ToastService } from '@shared/service/toast/toast.service';
import { Logger } from '@core';

const log = new Logger('ModalPopupsComponent');

@Component({
  selector: 'app-modal-popups',
  templateUrl: './modal-popups.component.html',
  styleUrls: [
    './modal-popups.component.scss',
    './modal-popups.component.mobile.scss',
  ],
})
export class ModalPopupsComponent implements OnInit {
  readonly tenantId = environment.tenantId;
  @Input()
  pageContent: (
    | StrapiGalleryComponent
    | StrapiImageComponent
    | StrapiParagraphComponent
  )[];

  @Input()
  components: StrapiComponent;
  @Input()
  displayName: string;
  modalReference: any;

  componentTypes: typeof StrapiComponentTypeEnum = StrapiComponentTypeEnum;
  public imageFormat = StrapiImageSizeEnum.small;

  constructor(public modal: NgbModal, private toastService: ToastService) {}

  ngOnInit(): void {
    log.debug('Component init started..');
  }

  openModal(content: any): void {
    this.toastService.removeAllToasts();
    this.modalReference = this.modal.open(content, {
      centered: true,
      windowClass: 'modal-window',
    });
    setTimeout(() => {
      document
        .getElementsByClassName('modal-scrollable-content')[0]
        ?.scrollTo(null, 0);
    }, 0);
  }

  closeModal() {
    this.modalReference.close();
  }
}
