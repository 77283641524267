import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StrapiFooterLarge } from '@nursing/pwn-cms-model/lib';
import { environment } from '@env/environment';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LargeFooterService {
  private readonly tenantId = environment.tenantId;

  constructor(private http: HttpClient, private state: TransferState) {}

  getLargeFooter(): Observable<StrapiFooterLarge> {
    type T = StrapiFooterLarge;
    const key = makeStateKey<T>('LargeFooterService_fl');
    const value: StrapiFooterLarge = this.state.get<T>(key, null);
    if (value) {
      return of(value);
    }
    return this.http.get<T>(`/strapi-proxy/${this.tenantId}/footer-large`).pipe(
      map((x) => {
        this.state.set<T>(key, x);
        return x;
      })
    );
  }
}
