<div *ngIf="articleListPage">
  <ng-container
    *ngFor="let component of articleListPage?.topContent"
    [ngSwitch]="component.__component"
  >
    <app-ad-video
      *ngSwitchCase="componentTypes.AdVideo"
      [component]="component"
    ></app-ad-video>
  </ng-container>
  <div
    class="top-content-container"
    *ngFor="let component of articleListPage?.topContent"
    [ngSwitch]="component.__component"
  >
    <app-header
      *ngSwitchCase="componentTypes.ArticleListHeader"
      [component]="component"
      [id]="categoryId"
    ></app-header>
    <app-ad
      *ngSwitchCase="componentTypes.Ad"
      [component]="component"
      [categories]="categories"
    ></app-ad>
  </div>
  <div class="middle-content-container">
    <div class="side-content-container">
      <div
        *ngFor="let component of articleListPage?.sideContent"
        [ngSwitch]="component.__component"
      >
        <app-ad
          *ngSwitchCase="componentTypes.Ad"
          [component]="component"
          [categories]="categories"
        ></app-ad>
        <app-category-list
          *ngSwitchCase="componentTypes.CategoryList"
          [categoryId]="categoryId"
          [component]="component"
        ></app-category-list>
        <app-custom-html
          *ngSwitchCase="componentTypes.CustomHtml"
          [component]="component"
        ></app-custom-html>
        <app-newsletter
          *ngSwitchCase="componentTypes.Newsletter"
          [component]="component"
          [style]="'small'"
        ></app-newsletter>
      </div>
    </div>
    <div class="main-content-container">
      <div
        *ngFor="let component of articleListPage?.mainContent"
        [ngSwitch]="component.__component"
      >
        <ng-container *ngSwitchCase="componentTypes.ArticleList">
          <app-article-grid
            *ngIf="listingPageView === StrapiListingPageViewEnum.grid"
            [searchData]="searchData"
          ></app-article-grid>
          <app-article-list
            *ngIf="listingPageView !== StrapiListingPageViewEnum.grid"
            [searchData]="searchData"
          ></app-article-list>
        </ng-container>
        <app-ad
          *ngSwitchCase="componentTypes.Ad"
          [component]="component"
          [categories]="categories"
        ></app-ad>
        <app-custom-html
          *ngSwitchCase="componentTypes.CustomHtml"
          [component]="component"
        ></app-custom-html>
      </div>
    </div>
  </div>
  <div class="bottom-content-container">
    <div
      *ngFor="let component of articleListPage?.bottomContent"
      [ngSwitch]="component.__component"
    >
      <app-ad
        *ngSwitchCase="componentTypes.Ad"
        [component]="component"
        [categories]="categories"
      ></app-ad>
      <app-partner-list
        *ngSwitchCase="componentTypes.Partners"
        [component]="component"
        [isFull]="true"
      ></app-partner-list>
      <app-newsletter
        *ngSwitchCase="componentTypes.Newsletter"
        [component]="component"
      ></app-newsletter>
      <app-bookstore
        *ngSwitchCase="componentTypes.Bookstore"
        [component]="component"
        [categoryId]="categoryId"
      ></app-bookstore>
    </div>
  </div>
  <app-large-footer *ngIf="articleListPage.footerLarge"></app-large-footer>
</div>
