import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ElkDocumentObject,
  ElkSearchResp,
  LogicalOperatorEnum,
  SearchBarSortOptions,
  SearchCriteria,
  SearchQueryTypeEnum,
  SortDirectionEnum,
  StrapiConfiguration,
  StrapiModelTypeEnum,
  StrapiVoivodeshipEnum,
} from '@nursing/pwn-cms-model/lib';
import { SearchService } from '@shared/service/elastic-search/search.service';
import { ConfigurationService } from '@shared/service/configuration/configuration.service';
import { SearchBarResult } from '@shared/model/search-bar/search-bar-result';
import { SearchBarInputData } from '@shared/model/search-bar/search-bar-input-data';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  QueryParamsHandling,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AuthenticationService } from '@shared/service/authentication/authentication.service';
import { UserProductService } from '@shared/service/user-product/user-product.service';
import { Logger } from '@core';

const log = new Logger('SearchBarComponent');

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: [
    './search-bar.component.scss',
    './search-bar.component.mobile.scss',
  ],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Input() set _isExtraBarActive(val: boolean) {
    this.isExtraBarActive = val;
  }

  @Input()
  hiddenMode = false;

  @Input()
  searchType: string;

  @Input()
  hideSearchOptions = false;

  @Input()
  searchForFavorites = false;

  @Input() set isGridView(val: boolean) {
    this._isGridView = val;
  }

  @Input()
  set data(value: SearchBarInputData) {
    this.setCritSearch(value);
  }

  @Input()
  set pageChanged(page: number | undefined) {
    if (page) {
      this.page = page;
      this.searchCriteria.pagination = {
        from: (this.page - 1) * this.itemsPerPage,
        size: this.itemsPerPage,
      };
      this.search(true);
    }
  }

  @Input()
  modelType: StrapiModelTypeEnum[];

  @Output()
  searchBarResult = new EventEmitter<SearchBarResult>();

  @Output()
  resetPage = new EventEmitter<number>();

  routerEventsSubscription: Subscription;
  querySubscription: Subscription | undefined;
  queryPresented: boolean;
  totalElements: number;
  page: number;
  itemsPerPage: number;
  @Input()
  inputPlaceholder = 'Szukaj';
  @Input()
  inputExtraBarPlaceholder = 'Szukaj';

  _isGridView = false;
  isExtraBarActive = false;
  currentOption = SearchBarSortOptions.accuracy;
  sortOptions = Object.values(SearchBarSortOptions);
  voivodeships: string[] = Object.values(StrapiVoivodeshipEnum);
  focusedOnInput = false;
  onPopState = false;
  searchCriteria: SearchCriteria;
  mainQuery: string;
  workplaceQuery: string;
  cityQuery: string;
  sortDirectionConverter: Map<string, SortDirectionEnum> = new Map<
    string,
    SortDirectionEnum
  >([
    [SearchBarSortOptions.newest.toString(), SortDirectionEnum.Dsc],
    [SearchBarSortOptions.oldest.toString(), SortDirectionEnum.Asc],
    [SearchBarSortOptions.accuracy.toString(), null],
  ]);

  constructor(
    private searchService: SearchService,
    private configurationService: ConfigurationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private userProductService: UserProductService
  ) {}

  @HostListener('window:keyup', ['$event'])
  onSearch(event?: KeyboardEvent, element?: any): void {
    if (
      (this.focusedOnInput &&
        (event?.code === 'Enter' || event?.code === 'NumpadEnter')) ||
      !event
    ) {
      this.searchCriteria.pagination = {
        from: 0,
        size: this.itemsPerPage,
      };
      this.resetPage.emit(1);
      this.setCurrentSortOption(SearchBarSortOptions.accuracy, false, true);
    }
  }

  ngOnInit(): void {
    this.querySubscription = this.activatedRoute.queryParams
      .pipe(take(1))
      .subscribe((params) => {
        this.mainQuery = params.query;
        this.workplaceQuery = params.workplace;
        this.cityQuery = params.city;
        if (params.query) {
          this.currentOption = SearchBarSortOptions.accuracy;
        }
      });
    this.routerEventsSubscription = this.router.events.subscribe((event) => {
      this.onPopState = false;
      if (event instanceof NavigationStart) {
        this.onPopState = event.navigationTrigger === 'popstate';
      }
      if (
        event instanceof NavigationEnd &&
        !this.searchService.shouldIgnoreNextEventAndReset()
      ) {
        this.search(false);
      }
    });
    this.userProductService.refreshFavorites$.subscribe((status) => {
      if (status) {
        this.search(false);
        this.userProductService.refreshFavorites.next(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.querySubscription?.unsubscribe();
    this.routerEventsSubscription?.unsubscribe();
  }

  setCurrentSortOption(
    selectedOption?: any,
    optionChangedOnly?: boolean,
    router?: boolean
  ): void {
    if (optionChangedOnly) {
      this.currentOption = selectedOption;
      this.searchCriteria.sort = this.sortDirectionConverter.get(
        this.currentOption
      );
      router ? this.navigateToSearchWithParams('preserve') : this.search();
      return;
    }

    if (this.mainQuery) {
      this.currentOption = selectedOption;
      this.searchCriteria.sort = this.sortDirectionConverter.get(
        this.currentOption
      );
    } else {
      this.currentOption = SearchBarSortOptions.newest;
      this.searchCriteria.sort = this.sortDirectionConverter.get(
        this.currentOption
      );
    }
    router ? this.navigateToSearchWithParams('merge') : this.search();
  }

  search(pageChanged?: boolean): void {
    if (this.onPopState) {
      this.currentOption = SearchBarSortOptions.accuracy;
      this.searchCriteria.sort = this.sortDirectionConverter.get(
        this.currentOption
      );
    }
    let queryParamsHandling: QueryParamsHandling = 'preserve';
    if (this.hiddenMode) {
      queryParamsHandling = 'merge';
    }
    this.navigateToSearchWithParams(queryParamsHandling).then(() => {
      this.activatedRoute.queryParams.subscribe((params) => {
        this.mainQuery = params.query;
        this.workplaceQuery = params.workplace;
        this.cityQuery = params.city;
        this.searchCriteria.query = this.searchService.getQuery(
          this.mainQuery,
          this.cityQuery,
          this.workplaceQuery
        );
        if (this.workplaceQuery) {
          this.isExtraBarActive = true;
        }
      });
      if (this.searchType === 'favorites') {
        this.searchByType(pageChanged, 'favorites');
      } else if (this.searchType === 'bought') {
        this.searchByType(pageChanged, 'bought');
      } else {
        this.searchAny(pageChanged);
      }
    });
  }

  searchAny(pageChanged: boolean): void {
    this.searchService
      .search(this.searchCriteria)
      .subscribe((res: ElkSearchResp) => {
        const totalHits = res.hits.total.value;
        const totalPages = Math.ceil(totalHits / this.itemsPerPage);
        const data: ElkDocumentObject[] = res.hits.hits.map(
          (hit) => hit._source
        );
        this.searchBarResult.emit(
          new SearchBarResult(data, totalHits, totalPages, this.itemsPerPage)
        );
        if (pageChanged !== true && this.page > totalPages) {
          this.resetPage.emit(1);
        }
        this.totalElements = totalHits;
      });
  }

  searchByType(pageChanged: boolean, typeToSearch: string): void {
    this.searchService
      .searchType(
        this.searchCriteria,
        environment.tenantId,
        this.authService.credentials.email,
        typeToSearch
      )
      .subscribe((res: ElkSearchResp) => {
        const totalHits = res.hits.total.value;
        const totalPages = Math.ceil(totalHits / this.itemsPerPage);
        const data: ElkDocumentObject[] = res.hits.hits.map(
          (hit) => hit._source
        );
        this.searchBarResult.emit(
          new SearchBarResult(data, totalHits, totalPages, this.itemsPerPage)
        );
        if (pageChanged !== true && this.page > totalPages) {
          this.resetPage.emit(1);
        }
        this.totalElements = totalHits;
      });
  }

  navigateToSearchWithParams(
    queryParamsHandling: QueryParamsHandling
  ): Promise<boolean> {
    const currentPath =
      typeof location !== 'undefined' ? location.pathname : '';
    return this.router.navigate([currentPath], {
      queryParams: {
        query: this.mainQuery || null,
        workplace: this.workplaceQuery || null,
        city: this.cityQuery || null,
      },
      queryParamsHandling,
    });
  }

  setItemsPerPage(configuration: StrapiConfiguration): number {
    if (this.modelType && this.modelType.length === 1 && this._isGridView) {
      return configuration.searchRowsPerPage * 3;
    } else {
      return configuration.searchRowsPerPage;
    }
  }

  setCritSearch(value: SearchBarInputData) {
    this.querySubscription = this.activatedRoute.queryParams
      .pipe(take(1))
      .subscribe((params) => {
        this.mainQuery = value.query ?? params.query;
        this.workplaceQuery = value.workplace ?? params.workplace;
        this.cityQuery = value.city ?? params.city;

        if (this.mainQuery) {
          this.queryPresented = true;
          this.currentOption =
            value.forcedSearchOption ?? SearchBarSortOptions.accuracy;
        } else {
          this.queryPresented = false;
          this.currentOption =
            value.forcedSearchOption ?? SearchBarSortOptions.newest;
        }

        this.modelType = value.modelType;
        this.configurationService
          .getConfiguration()
          .subscribe((res: StrapiConfiguration) => {
            this.itemsPerPage = this.setItemsPerPage(res);
          });

        this.searchCriteria = {
          query: this.searchService.getQuery(
            this.mainQuery,
            this.cityQuery,
            this.workplaceQuery
          ),
          filter: {
            type: SearchQueryTypeEnum.Bool,
            logicalOperator: LogicalOperatorEnum.And,
            criteria: this.searchService.getFilterCriteriaFromInputData(
              this.modelType,
              value.categoryIds,
              value.authorIds,
              value.tagId,
              value.sourceId,
              value.type,
              value.voivodeship,
              value.dateFrom,
              value.dateTo,
              value.expert,
              value.inArticleIds
            ),
          },
          pagination: {
            from: (this.page - 1) * this.itemsPerPage,
            size: this.itemsPerPage,
          },
          sort: this.sortDirectionConverter.get(this.currentOption),
        };
        this.search();
        this.querySubscription?.unsubscribe();
      });
  }
}
