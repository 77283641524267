import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  SimpleCategory,
  StrapiAdComponent,
  StrapiAdZone,
} from '@nursing/pwn-cms-model/lib';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AdService } from '@shared/service/ad/ad.service';
import { Subscription } from 'rxjs';
import { PlatformService } from '@app/@shared/service/ssr/platform.service';

@Component({
  selector: 'app-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.scss', './ad.component.mobile.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  component: StrapiAdComponent;

  subscription: Subscription;
  @ViewChild('mobileChild', { static: true }) mobileChild: ElementRef;

  @Input()
  set categories(categories: SimpleCategory[]) {
    this.adService.setAdContext(categories);
  }

  mobileUrl: SafeHtml;
  desktopUrl: SafeHtml;
  showMobile = false;
  showDesktop = false;
  adZone: StrapiAdZone;
  zoneLoaded = false;

  constructor(
    private _sanitizer: DomSanitizer,
    private adService: AdService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {}
  ngAfterViewInit(): void {
    this.securedSideContent();
  }

  ngOnInit(): void {
    if (PlatformService.isBrowser) {
      this.adService.getAdZone(this.component.adZone.id).subscribe((az) => {
        this.adZone = az;
        this.refreshContext();
      });
      this.subscription = this.adService.getCtx().subscribe((ctx) => {
        this.refreshContext();
        setTimeout(() => {
          if (
            document.querySelector('.desktop.ad-container iframe')
              ?.clientHeight == 149
          ) {
            this.showDesktop = false;
          }
          if (
            document.querySelector('.mobile.ad-container iframe')
              ?.clientHeight == 149
          ) {
            this.showMobile = false;
          }
          this.zoneLoaded = true;
          this.cdr.detectChanges();
        }, 1000);
      });
    }
    if (typeof window !== 'undefined' && window != null) {
      window.innerWidth < 1000
        ? this.setDisplayUnsetForElementAndParents(
            this.mobileChild?.nativeElement
          )
        : null;
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private removeAdLayerClass(element: HTMLElement): void {
    if (element) {
      element.className = 'sideLay';
    }
  }

  private setDisplayUnsetForElementAndParents(element: HTMLElement): void {
    if (element) {
      this.renderer.setStyle(element, 'display', 'unset');
      const parentElement = element.parentElement;
      if (parentElement) {
        this.renderer.setStyle(parentElement, 'display', 'unset');
        const grandparentElement = parentElement.parentElement;
        if (grandparentElement) {
          this.renderer.setStyle(grandparentElement, 'display', 'unset');
          const grandGranderparentElement = grandparentElement.parentElement;
          if (grandGranderparentElement) {
            this.renderer.setStyle(
              grandGranderparentElement,
              'display',
              'unset'
            );
          }
          const grandgranderparentElementLast =
            grandGranderparentElement.parentElement;
          if (grandgranderparentElementLast) {
            this.renderer.setStyle(
              grandgranderparentElementLast,
              'display',
              'unset'
            );
          }
        }
      }
    }
  }

  securedSideContent() {
    const mobileChildElement = this.mobileChild?.nativeElement;
    if (window.innerWidth < 1000) {
      let currentElement = mobileChildElement;
      while (currentElement) {
        if (currentElement.tagName === 'APP-ARTICLE-SIDE-CONTENT') {
          const adLayerElement = currentElement.querySelector('.ad-layer');
          this.removeAdLayerClass(adLayerElement);
          break;
        }
        currentElement = currentElement.parentElement;
      }
    }
  }
  refreshContext() {
    if (this.adZone) {
      const m = this.adService.getAdUrl(this.adZone, false);
      if (m == null || m === undefined || m.length === 0) {
        this.showMobile = false;
      } else {
        this.showMobile = true;
        this.mobileUrl = this._sanitizer.bypassSecurityTrustHtml(m);
      }

      const d = this.adService.getAdUrl(this.adZone, true);
      if (d == null || d === undefined || d.length === 0) {
        this.showDesktop = false;
      } else {
        this.showDesktop = true;
        this.desktopUrl = this._sanitizer.bypassSecurityTrustHtml(d);
      }
      this.securedSideContent();
    } else {
      this.zoneLoaded = false;
    }
    this.cdr.markForCheck();
  }

  isBrowser(): boolean {
    return PlatformService.isBrowser;
  }
}
