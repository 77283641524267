import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  StrapiComponent,
  StrapiImageClass,
  StrapiParagraphComponent,
} from '@nursing/pwn-cms-model/lib';
import { environment } from '@env/environment';

@Component({
  selector: 'app-footer-static-item',
  templateUrl: './footer-static-item.component.html',
  styleUrls: [
    './footer-static-item.component.scss',
    './footer-static-item.component.mobile.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FooterStaticItemComponent {
  readonly tenantId = environment.tenantId;
  _item: StrapiParagraphComponent;
  paragraphContent: SafeHtml;
  imageUrl: string;
  backgroundImageUrl: string;

  @Input() set item(value: StrapiComponent) {
    if (value) {
      this._item = value as StrapiParagraphComponent;
    }
    const { content, image, backgroundImage } = this._item;
    if (content) {
      this.paragraphContent = this._sanitizer.bypassSecurityTrustHtml(content);
    }
    if (image && image.url) {
      this.imageUrl = new StrapiImageClass(image).getPublicImageUrl(
        environment.serverUrl + '/strapi-proxy/' + this.tenantId
      );
    }
    if (backgroundImage && backgroundImage.url) {
      this.backgroundImageUrl = new StrapiImageClass(
        backgroundImage
      ).getPublicImageUrl(
        environment.serverUrl + '/strapi-proxy/' + this.tenantId
      );
    }
  }

  constructor(private _sanitizer: DomSanitizer) {}
}
