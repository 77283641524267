import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import {
  StrapiAdVideoComponent,
  StrapiImagePositionEnum,
} from '@nursing/pwn-cms-model/lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';

import { DOCUMENT } from '@angular/common';
import { AdService } from '@app/@shared/service/ad/ad.service';

@Component({
  selector: 'app-ad-video',
  templateUrl: './ad-video.component.html',
  styleUrls: ['./ad-video.component.scss'],
})
export class AdVideoComponent implements AfterViewInit {
  readonly tenantId = environment.tenantId;
  videoUrl: string = '';
  pauseEventUrl: string = '';
  redirectUrl: string = '';
  isPlayerVisible = false;
  fullScreenEventUrl: string = '';
  startEventUrl: string = '';
  endEventUrl: string = '';
  videoPlayer: any;
  adHtml: string = '';
  xmlData: '';
  xmlDoc: Document;
  @Input()
  component: StrapiAdVideoComponent;

  @ViewChild('videoPlayer') videoPlayerDom: ElementRef;
  @ViewChild('videoElement') videoElementDOM: ElementRef;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window && window.innerWidth > 768) {
      const videoContainer = this.videoPlayerDom?.nativeElement.parentElement;
      const windowHeight = window.innerHeight;
      const containerHeight = videoContainer.offsetHeight;
      const scrollTop =
        window.pageYOffset || document?.documentElement?.scrollTop;

      const newPosition = (windowHeight - containerHeight) / 2 + scrollTop;
      if (videoContainer) {
        videoContainer.style.top = `${newPosition}px`;
      }
    }
  }
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public modal: NgbModal,
    private adService: AdService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isPlayerVisible = true;
      setTimeout(() => {
        const videoContainer = this.videoPlayerDom?.nativeElement;
        this.videoPlayer = this.videoElementDOM?.nativeElement;
        if (this.component && videoContainer && this.videoPlayer) {
          if (this.component.url) {
            this.videoUrl = this.component.url;
            this.adService.getXmlData(this.videoUrl).subscribe((data: any) => {
              this.xmlData = data;
              this.parseXmlAndExtractVideoUrl();
            });
            if (
              this.component.positionPlace === StrapiImagePositionEnum.right
            ) {
              videoContainer.style.right = '0';
            } else if (
              this.component.positionPlace === StrapiImagePositionEnum.left
            ) {
              videoContainer.style.left = '0';
            }
            if (
              this.component.positionVerticalMobile ===
              StrapiImagePositionEnum.top
            ) {
              videoContainer.classList.remove('mobileBottom');
            } else {
              videoContainer.classList.add('mobileBottom');
            }
            if (this.component.positionFixed) {
              videoContainer.style.position = 'fixed';
            } else {
              videoContainer.style.position = 'absolute';
            }
          } else {
            this.isPlayerVisible = false;
          }
        } else {
          this.isPlayerVisible = false;
        }
        if (this.videoPlayer) {
          this.videoPlayer.addEventListener('ended', () => {
            const endEventTracking = this.xmlDoc.querySelector(
              'Tracking[event="complete"]'
            );
            if (endEventTracking) {
              const urlElement = endEventTracking.querySelector('URL');

              if (urlElement) {
                this.endEventUrl = urlElement.textContent.trim();
                this.adService.getXmlData(this.endEventUrl).subscribe();
              } else {
                console.error(
                  'Nie znaleziono tagu <URL> w zdarzeniu "end" w XML.'
                );
              }
            }
          });

          this.videoPlayer.addEventListener('pause', () => {
            const pauseEventTracking = this.xmlDoc.querySelector(
              'Tracking[event="pause"]'
            );
            if (pauseEventTracking) {
              const urlElement = pauseEventTracking.querySelector('URL');

              if (urlElement) {
                this.pauseEventUrl = urlElement.textContent.trim();
                this.adService.getXmlData(this.pauseEventUrl).subscribe();
              } else {
                console.error(
                  'Nie znaleziono tagu <URL> w zdarzeniu "pause" w XML.'
                );
              }
            }
          });
        }
      }, 0);
    }, 3000);
  }

  closePlayerBtn() {
    this.isPlayerVisible = false;
  }

  redirectToLink() {
    if (typeof window !== 'undefined') {
      const destinationUrlElement = this.xmlDoc.querySelector(
        'URL[id="destination"]'
      );
      if (destinationUrlElement) {
        this.redirectUrl = destinationUrlElement.textContent.trim();
        window.open(this.redirectUrl, '_blank');
      }
    }
  }

  handleFullscreenChange(event: Event): void {
    const videoElement = event.target as HTMLVideoElement;
    if (document && document.fullscreenElement === videoElement) {
      const fullScreenEventTracking = this.xmlDoc.querySelector(
        'Tracking[event="fullscreen"]'
      );
      if (fullScreenEventTracking) {
        const urlElement = fullScreenEventTracking.querySelector('URL');

        if (urlElement) {
          this.fullScreenEventUrl = urlElement.textContent.trim();
          this.adService.getXmlData(this.fullScreenEventUrl).subscribe();
        } else {
          console.error(
            'Nie znaleziono tagu <URL> w zdarzeniu "fullscreen" w XML.'
          );
        }
      }
    }
  }
  parseXmlAndExtractVideoUrl() {
    try {
      if (typeof DOMParser !== 'undefined') {
        const parser = new DOMParser();
        this.xmlDoc = parser.parseFromString(this.xmlData, 'text/xml');
        const urlFilm = this.xmlDoc
          .querySelector('MediaFile')
          .querySelector('URL');
        if (urlFilm) {
          this.videoUrl = urlFilm.textContent.trim();
          this.videoPlayer.load();
          this.videoPlayer.muted = true;
          const startEventTracking = this.xmlDoc.querySelector(
            'Tracking[event="start"]'
          );
          if (startEventTracking) {
            const urlElement = startEventTracking.querySelector('URL');
            if (urlElement) {
              this.startEventUrl = urlElement.textContent.trim();
              this.adService.getXmlData(this.startEventUrl).subscribe();
            } else {
              console.error(
                'Nie znaleziono tagu <URL> w zdarzeniu "start" w XML.'
              );
            }
          }
          this.videoPlayer.play();
        } else {
          console.error('not found');
        }
      }
    } catch (error) {
      console.error('cannot handle xml:', error);
    }
  }
}
