import { Component, Input, OnInit } from '@angular/core';
import { PlatformService } from '@app/@shared/service/ssr/platform.service';
import { StrapiMicroservicesComponent } from '@nursing/pwn-cms-model/lib';

@Component({
  selector: 'app-microservice-list',
  templateUrl: './microservice-list.component.html',
  styleUrls: [
    './microservice-list.component.scss',
    './microservice-list.component.mobile.scss',
  ],
})
export class MicroserviceListComponent {
  @Input()
  component: StrapiMicroservicesComponent;

  constructor() {}

  isBrowser(): boolean {
    return PlatformService.isBrowser;
  }
}
