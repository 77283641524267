import { Component, Input } from '@angular/core';
import { StrapiContextualAdComponent } from '@nursing/pwn-cms-model/lib';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-contextual-ad',
  templateUrl: './contextual-ad.component.html',
  styleUrls: ['./contextual-ad.component.scss'],
})
export class ContextualAdComponent {
  @Input()
  set component(val: StrapiContextualAdComponent) {
    this._component = val;
    this.content = this._sanitizer.bypassSecurityTrustHtml(
      this._component?.content
    );
  }

  _component: StrapiContextualAdComponent;

  content: any;

  constructor(private _sanitizer: DomSanitizer) {}
}
