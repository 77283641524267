<div
  *ngIf="isBrowser()"
  class="good-to-know-container"
  [style]="{
    'background-image': backgroundImage ? 'url(' + backgroundImage + ')' : ''
  }"
>
  <div class="content-container">
    <div class="title">
      <h1>{{ component.title }}</h1>
    </div>
    <div class="content">
      <app-good-to-know
        *ngFor="let article of articleList"
        [article]="article"
        [readMore]="component.readMore"
        [imageSize]="component?.imageSize"
      >
      </app-good-to-know>
    </div>
    <div class="display-all-container">
      <a
        [routerLink]="[
          '/artykuly',
          categoryService.getSlugId(component.categoryToSearchIn),
          component.tag.id
        ]"
      >
        <button>{{ component.displayAllLabel }}</button>
      </a>
    </div>
  </div>
</div>
