import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { MainMenuService } from '@shared/service/main-menu/main-menu.service';
import { MainMenuLink } from '@shared/model/menu/main-menu-link';
import {
  StrapiCategoryLinkComponent,
  StrapiComponentTypeEnum,
  StrapiImageClass,
  StrapiMainMenu,
  StrapiStaticPageLinkComponent,
} from '@nursing/pwn-cms-model/lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from '@app/@shared/service/configuration/configuration.service';
import { environment } from '@env/environment';
import { AuthenticationService } from '@app/@shared/service/authentication/authentication.service';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { CategoryService } from '@app/@shared/service/category/category.service';
import { Logger } from '@core';

const log = new Logger('MainMenuComponent');

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: [
    './main-menu.component.scss',
    './main-menu.component.mobile.scss',
    './main-menu.component.small.scss',
  ],
})
export class MainMenuComponent implements OnInit, OnDestroy {
  readonly tenantId = environment.tenantId;
  componentTypes: typeof StrapiComponentTypeEnum = StrapiComponentTypeEnum;

  menuHidden = true;

  mainMenuState: Subject<MainMenuLink> = new BehaviorSubject(null);

  elements: MainMenuLink[] = [];
  mainMenu: StrapiMainMenu;
  logo: StrapiImageClass = null;
  favicon: StrapiImageClass = null;

  menuElementsSubscription: Subscription = null;
  configurationSubscription: Subscription = null;

  globalSearchBarOpened = false;

  slugIdMap = new Map();

  categoriesSub: Subscription;

  public isLoggedIn$: Observable<boolean>;
  public isLoggedOut$: Observable<boolean>;

  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
    private mainMenuService: MainMenuService,
    private modal: NgbModal,
    private configurationService: ConfigurationService,
    private titleService: Title,
    private categoryService: CategoryService,
    @Optional() @Inject(DOCUMENT) private _document?: HTMLDocument
  ) {}

  ngOnInit() {
    this.isLoggedIn$ = this.authenticationService.isLoggedIn$;
    this.isLoggedOut$ = this.authenticationService.isLoggedOut$;

    log.debug('MainMenuComponent INIT');
    this.menuElementsSubscription = this.mainMenuService
      .getMenuElements()
      .subscribe((e) => this.loadMenu(e));
    this.configurationSubscription = this.configurationService
      .getConfiguration()
      .subscribe((e) => {
        if (e != null) {
          this.logo = new StrapiImageClass(e.logo);
          this.favicon = new StrapiImageClass(e.favicon);
          // this._document
          //   .getElementById('favIcon')
          //   .setAttribute('href', this.favicon.getPublicImageUrl(this.getServerUrl() + '/strapi-proxy'));
          // this.titleService.setTitle(e.title);
        }
      });
    this.categoriesSub = this.categoryService
      .getCategories()
      .subscribe((categories) => {
        categories.forEach((cat) => {
          this.slugIdMap.set(cat.id, this.categoryService.getSlugId(cat));
        });
      });
  }

  ngOnDestroy(): void {
    log.debug('MainMenuComponent DESTROY');
    this.menuElementsSubscription?.unsubscribe();
    this.configurationSubscription?.unsubscribe();
    this.categoriesSub?.unsubscribe();
  }

  loadMenu(e: StrapiMainMenu): void {
    this.mainMenu = e;
    e.elements?.forEach((element) => {
      this.readLink(element);
    });
  }

  readLink(
    element: StrapiCategoryLinkComponent | StrapiStaticPageLinkComponent
  ) {
    const link = new MainMenuLink();
    link.type = element.__component;
    link.displayName = element.displayName;

    switch (element.__component) {
      case StrapiComponentTypeEnum.CategoryLink:
        const categoryLink = element as StrapiCategoryLinkComponent;

        link.targetId = categoryLink.category.id;
        break;
      case StrapiComponentTypeEnum.StaticPageLink:
        const staticPage = element as StrapiStaticPageLinkComponent;

        if (
          !staticPage.externalLink &&
          !staticPage.internalLink &&
          staticPage.page
        ) {
          link.page = staticPage.page;
          link.external = false;
          break;
        }

        if (staticPage.externalLink) {
          link.targetId = staticPage.externalLink;
          link.external = true;
        } else {
          link.targetId = staticPage.internalLink;
          link.external = false;
        }
        break;
    }

    this.elements.push(link);
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  onMouseLeave() {
    this.mainMenuState.next(null);
  }

  openMenu(content: any): void {
    this.modal
      .open(content, {
        centered: true,
        windowClass: 'main-menu-content',
        keyboard: false,
        backdrop: 'static',
      })
      .result.then();
  }

  closeModal() {
    this.modal.dismissAll();
  }

  getServerUrl(): string {
    return environment.serverUrl;
  }

  logOut(): void {
    this.authenticationService.logout();
    this.modal.dismissAll();
  }

  goToLogin() {
    this.modal.dismissAll();
    this.authenticationService.setRedirectUrl(this.router.url);
  }
}
