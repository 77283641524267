import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { StrapiOccupationalGroup } from '@nursing/pwn-cms-model/lib';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OccupationalGroupService {
  private readonly tenantId = environment.tenantId;
  constructor(private http: HttpClient) {}

  getOccupationalGroupById(
    occupationalGroupId: string
  ): Observable<StrapiOccupationalGroup> {
    return this.http.get<StrapiOccupationalGroup>(
      `/strapi-proxy/${this.tenantId}/occupational-group/${occupationalGroupId}`
    );
  }
}
