import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Category, StrapiMainMenuLayoutEnum } from '@nursing/pwn-cms-model/lib';
import { MainMenuLink } from '@shared/model/menu/main-menu-link';
import { CategoryService } from '@shared/service/category/category.service';
import { Subject, Subscription } from 'rxjs';
import { Logger } from '@core';

const log = new Logger('CategoryPopupComponent');

@Component({
  selector: 'app-category-popup',
  templateUrl: './category-popup.component.html',
  styleUrls: ['./category-popup.component.scss'],
  animations: [
    trigger('showHideTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('200ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class CategoryPopupComponent implements OnInit, OnDestroy {
  @Input()
  mainMenuState: Subject<MainMenuLink>;

  @Input()
  mainMenuLayout: StrapiMainMenuLayoutEnum;

  @Input()
  alwaysVisible: boolean;

  mainMenuLink: MainMenuLink;
  childCategories: string[];
  visible = false;

  // subscriptions
  menuStateSubscription: Subscription;
  categorySubcription: Subscription;

  constructor(private categoryService: CategoryService) {
    this.childCategories = [];
  }

  ngOnInit(): void {
    log.debug('CategoryPopupComponent INIT');
    this.menuStateSubscription = this.mainMenuState.subscribe((link) =>
      this.onMainMenuStateChanged(link as MainMenuLink)
    );
  }

  ngOnDestroy(): void {
    log.debug('CategoryPopupComponent DESTROY');
    this.menuStateSubscription?.unsubscribe();
    this.categorySubcription?.unsubscribe();
  }

  isTiles(): boolean {
    return this.mainMenuLayout === StrapiMainMenuLayoutEnum.tiles;
  }

  onMainMenuStateChanged(link: MainMenuLink) {
    // if already opened then close
    log.debug(
      'CategoryPopupComponent: Received main menu state changed to: ' +
        (link == null ? 'null' : link.displayName)
    );
    if (
      link == null ||
      link.type !== 'link-components.category-link' ||
      (this.mainMenuLink != null &&
        this.mainMenuLink.targetId === link.targetId)
    ) {
      log.debug('CategoryPopupComponent: closing popup');
      this.mainMenuLink = null;
      this.visible = false;
    } else {
      // show
      log.debug('CategoryPopupComponent: opening popup');
      this.mainMenuLink = link;
      this.categorySubcription?.unsubscribe();
      this.categorySubcription = this.categoryService
        .getCategories()
        .subscribe((c) => this.fetchCategory(c));
    }
  }

  fetchCategory(categories: Category[]) {
    this.childCategories = [];

    if (categories != null) {
      for (const element of categories) {
        if (element.id === this.mainMenuLink.targetId) {
          this.childCategories = element.childCategories;
          if (
            this.childCategories == null ||
            this.childCategories.length === 0
          ) {
            log.debug('CategoryPopupComponent: no categories - closing popup');
            this.mainMenuLink = null;
            this.visible = false;
          } else {
            this.visible = true;
          }
          break;
        }
      }
    }
  }
}
