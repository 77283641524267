<div class="closed-marker-container">
  <div
    [innerHtml]="closedMarkerHtml"
    class="closed-marker-container-info ck-content"
  ></div>
  <p></p>
  <div *ngIf="!authService.isAuthenticated()">
    <a [routerLink]="['/rejestracja']" (click)="setRedirects()">
      <button>Zarejestruj się</button>
    </a>
    <p class="have-account-text">
      Masz już konto?
      <a [routerLink]="['/logowanie']" (click)="setRedirects()">Zaloguj się</a>
    </p>
  </div>
  <div *ngIf="canBay()">
    <a [routerLink]="['/produkty']" (click)="setRedirects()">
      <button>Kup dostęp</button>
    </a>
  </div>
</div>
