import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title, TransferState } from '@angular/platform-browser';
import {
  StrapiConfiguration,
  StrapiImage,
  StrapiImageClass,
} from '@nursing/pwn-cms-model/lib';
import { MetaOgType } from '@shared/model/meta/meta-og-type.enum';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../configuration/configuration.service';
import { NavigationStart, Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class MetaDataService {
  private readonly tenantId: string = environment.tenantId;
  private readonly serverUrl: string = environment.serverUrl;
  private defaultConfigTitle = '';
  private defaultConfigDesc = '';
  private defaultConfigLogoUrl = '';
  private defaultConfigFaviconUrl = '';

  private META_MAP = [
    {
      addr: '/',
      title: 'Portal dla pielęgniarek, pielęgniarzy, położnych, studentów',
      desc:
        'Portal Wydawnictwa Lekarskiego PZWL dla pielęgniarek, pielęgniarzy, położnych oraz studentów tych kierunków. Źródło wiedzy i praktycznych informacji.',
      keywords:
        'nursing, pielęgniarka, położna, zawód pielęgniarki, zawód położnej, praca pielęgniarki, praca położnej, PZWL',
    },
    {
      addr: '/zostan-autorem',
      title:
        'Zostań naszym Autorem w dziedzinie pielęgniarstwa lub położnictwa',
      desc:
        'Podziel się swoją wiedzą z zakresu pielęgniarstwa lub położnictwa. Zostań naszym Autorem i opowiedz o swojej dziedzinie oraz doświadczeniach zawodowych.',
      keywords: 'nursing, ekspert medyczny, wiedza medyczna, autor',
    },
    {
      addr: '/artykuly/covid19-5f8ec21b9e9202a65d81a56b',
      title: 'Koronawirus. Aktualne dane naukowe, procedury pielęgniarskie',
      desc:
        'Aktualne dane naukowe na temat zakażenia COVID-19. Procedury pielęgniarskie w obliczu pandemii. Opieka nad pacjentem z potwierdzonym zakażeniem koronawirusem.',
      keywords:
        'COVID-19, SARS-CoV-2, koronawirus, pandemia, epidemiologia, wirusy',
    },
    {
      addr: '/logowanie',
      title: 'Zyskaj bezpłatny dostęp do wiedzy medycznej | Nursing',
      desc:
        'Zyskaj dostęp do specjalistycznych artykułów medycznych. Dołącz do grupy kilkunastu tysięcy pielęgniarek i położnych. Rejestracja jest całkowicie darmowa.',
      keywords: 'nursing, pielęgniarka, położna, wiedza',
    },
    {
      addr: '/wyniki-wyszukiwania',
      title: 'Wyszukaj treści dla swojej specjalizacji | Nursing.com.pl',
      desc:
        'Wyszukaj interesujące Cię materiały z położnictwa i pielęgniarstwa każdej specjalizacji. Na portalu znajdziesz artykuły najlepszych polskich specjalistów.',
      keywords: 'nursing, pielęgniarka, położna, wiedza, artykuły medyczne',
    },
    {
      addr: '/nasi-autorzy',
      title: 'Eksperci w dziedzinie pielęgniarstwa i położnictwa | Nursing',
      desc:
        'Artykuły z zakresu pielęgniarstwa, położnictwa oraz wielu dziedzin medycznych przygotowują najlepsi polscy specjaliści, którzy stale współpracują z portalem.',
      keywords:
        'nursing, ekspert medyczny, wiedza medyczna, autor, lider opinii',
    },
    {
      addr: '/artykuly/artykuly-5f33a473643341036688beac',
      title: 'Artykuły cenionych ekspertów medycznych | Nursing.com.pl',
      desc:
        'Artykuły naukowe i specjalistyczne dla pielęgniarek i położnych poparte doświadczeniem wybitnych ekspertów. Aktualna i rzetelna wiedza dla każdej specjalizacji.',
      keywords: 'nursing, pielęgniarka, położna, wiedza, wiedza medyczne',
    },
    {
      addr: '/artykuly/pielegniarstwo-internistyczne-5f33a474643341036688beb6',
      title:
        'Wiedza z zakresu pielęgniarstwa internistycznego | Nursing.com.pl',
      desc:
        'Specjalistyczne artykuły dla pielęgniarstwa internistycznego. Aktualna wiedza, procedury i praktyczne informacje przydatne w codziennej pracy pielęgniarki.',
      keywords: 'nursing, pielęgniarka, położna, artykuły, wiedza',
    },
    {
      addr: '/artykuly/pielegniarstwo-operacyjne-5f33a474643341036688becb',
      title: 'Wiedza z zakresu pielęgniarstwa operacyjnego | Nursing.com.pl',
      desc:
        'Specjalistyczne artykuły dla pielęgniarstwa operacyjnego. Aktualna wiedza i praktyczne informacje przydatne w codziennej pracy pielęgniarki operacyjnej.',
      keywords: 'nursing, pielęgniarstwo operacyjne, operacje, blok operacyjny',
    },
    {
      addr: '/artykuly/pielegniarstwo-ogolne-5f33a473643341036688beb4',
      title: 'Wiedza z zakresu pielęgniarstwa ogólnego | Nursing.com.pl',
      desc:
        'Specjalistyczne artykuły dla pielęgniarstwa ogólnego. Aktualna wiedza, felietony i praktyczne informacje przydatne w codziennej pracy każdej pielęgniarki.',
      keywords:
        'nursing, pielęgniarstwo ogólne, praca pielęgniarki, zawód pielęgniarki',
    },
    {
      addr: '/wydarzenia',
      title: 'Szkolenia, webinaria, kursy dla pielęgniarek i położnych',
      desc:
        'Konferencje, szkolenia, webinaria, kursy stacjonarne i online, warsztaty dla pielęgniarek i położnych. Kalendarium dla różnych specjalizacji pielęgniarskich.',
      keywords:
        'nursing, wydarzenia, kalendarium, szkolenia dla pielęgniarek, szkolenia dla położnych',
    },
    {
      addr: '/artykuly/poloznictwo-5f33a473643341036688beb0',
      title:
        'Artykuły specjalistyczne z dziedziny położnictwa | Nursing.com.pl',
      desc:
        'Specjalistyczne artykuły dla położnych. Aktualna wiedza z zakresu ciąży i porodu, procedury, praktyczne informacje przydatne w codziennej pracy każdej położnej.',
      keywords: 'nursing, położnictwo, położna, praca położnej',
    },
    {
      addr: '/artykuly/pielegniarstwo-pediatryczne-5f33a474643341036688beb7',
      title: 'Wiedza z zakresu pielęgniarstwa pediatrycznego | Nursing.com.pl',
      desc:
        'Specjalistyczne artykuły dla pielęgniarstwa pediatrycznego. Aktualna wiedza, procedury i praktyczne informacje przydatne w opiece pielęgniarskiej nad dziećmi.',
      keywords:
        'nursing, pielęgniarstwo pediatryczne, pediatria, opieka pediatryczna',
    },
    {
      addr: '/ksiazki/5f33a473643341036688bead',
      title: 'Publikacje dla pielęgniarek i położnych | Nursing.com.pl',
      desc:
        'Polecane książki Wydawnictwa Medycznego PZWL z zakresu pielęgniarstwa specjalistycznego i położnictwa. Poradniki dla pacjentów, publikacje przydatne w zawodzie.',
      keywords:
        'nursing, PZWL, książki medyczne, książki dla pielęgniarek, książki dla położnych',
    },
    {
      addr: '/artykuly/prawo-5fa1097e90d1843916077a70',
      title: 'Regulacja prawne. Zawód pielęgniarki i położnej | Nursing.com.pl',
      desc:
        'Zawód pielęgniarki i położnej. Zmiany prawne, rozporządzenia, ustawy w zakresie pracy, kształcenia, wynagrodzenia, świadczeń opieki zdrowotnej, ochrony zdrowia.',
      keywords:
        'nursing, prawo wykonywania zawodu, zawód pielęgniarki, zawód położnej, kształcenie pielęgniarek, kształcenie położnych',
    },
    {
      addr: '/o-nas',
      title: 'Portal Wydawnictwa Lekarskiego PZWL | Nursing.com.pl',
      desc:
        'Profesjonalny portal dla położnych i pielęgniarek wszystkich specjalizacji, tworzony od 2009 r. przez PZWL Wydawnictwo Lekarskie. Ekspercka wiedza medyczna.',
      keywords:
        'nursing, pielęgniarka, położna, specjalizacje pielęgniarskie, wiedza medyczna, PZWL',
    },
    {
      addr: '/kontakt',
      title: 'Masz pytanie? Napisz. Kontakt z redakcją | Nursing.com.pl',
      desc:
        'Masz pytania do naszej redakcji? A może masz pomysł na tekst ekspercki lub chcesz się z nami podzielić swoim artykułem? Nie zwlekaj. Skontaktuj się z nami!',
      keywords:
        'nursing, PZWL, portal dla pielęgniarek, portal dla położnych, redakcja medyczna',
    },
    {
      addr: '/artykuly/pielegniarstwo-chirurgiczne-5f33a473643341036688beb5',
      title: 'Wiedza z zakresu pielęgniarstwa chirurgicznego | Nursing.com.pl',
      desc:
        'Specjalistyczne artykuły dla pielęgniarstwa chirurgicznego. Aktualna wiedza i praktyczne informacje z zakresu chirurgii, przydatne w w zawodzie pielęgniarki.',
      keywords:
        'nursing, pielęgniarstwo chirurgiczne, chirurgia, zabiegi chirurgiczne',
    },
    {
      addr: '/artykuly/aktualnosci-5f33a473643341036688beab',
      title: 'Aktualne informacje ze świata medycyny | Nursing.com.pl',
      desc:
        'Zawsze aktualne informacje ze świata medycyny, najnowsze rozwiązania, standardy i obecnie stosowane technologie. Wiadomości dla pielęgniarek i położnych.',
      keywords:
        'nursing, aktualności, medycyna, standardy medyczne, praca pielęgniarki, praca położnej',
    },
    {
      addr: '/reset-hasla',
      title: 'Zresetuj hasło i ciesz się fachową wiedzą | Nursing.com.pl',
      desc:
        'Nie pamiętasz hasła do swojego konta na portalu Nursing? Zresetuj hasło i ponownie czytaj artykuły eksperckie oraz aktualności dla pielęgniarek i położnych.',
      keywords: 'nursing, portal dla pielęgniarek, portal dla położnych',
    },
    {
      addr: '/rejestracja',
      title: 'Fachowa wiedza medyczna. Zarejestruj się | Nursing.com.pl',
      desc:
        'Nie posiadasz jeszcze konta na portalu Nursing? Zyskaj dostęp do specjalistycznych artykułów dla pielęgniarek i położnych. Rejestracja jest bezpłatna.',
      keywords: 'nursing, portal dla pielęgniarek, portal dla położnych',
    },
    {
      addr: '/artykuly/pielegniarstwo-5f33a473643341036688beb1',
      title: 'Specjalistyczna wiedza z zakresu pielęgniarstwa | Nursing.com.pl',
      desc:
        'Specjalistyczne artykuły z dziedziny pielęgniarstwa. Aktualna i rzetelna wiedza dla każdej specjalizacji, poparta doświadczeniem wybitnych ekspertów.',
      keywords:
        'nursing, pielęgniarka, pielęgniarstwo, nauka zawodu, zawód pielęgniarki, kształcenie pielęgniarki',
    },
    {
      addr: '/artykul/polozne-w-social-mediach-6010269b6ce92c43e2f8fe19',
      title: 'Położne w social mediach | Nursing.com.pl',
      desc:
        'Media społecznościowe wyzwaniem dla położnych. Jak znaleźć czas na studia, pracę i działalność w sieci? Jak dzielić się wiedzą medyczną w social mediach? ',
      keywords:
        'nursing, położna, praca położnej, media społecznościowe, social media',
    },
    {
      addr:
        '/artykul/specyficzne-problemy-zwiazane-z-operacjami-w-ortopedii-i-traumatologii-6001a2cb6ce92c2974f8fdd7',
      title: 'Operacje w ortopedii i traumatologii - ułożenie pacjenta',
      desc:
        'Prawidłowe ułożenie pacjenta do operacji ortopedycznej/traumatologicznej. Zastosowanie opaski uciskowej i "bezpieczny" czas utrzymywania niedokrwienia.',
      keywords:
        'nursing, operacje, ortopedia, traumatologia, operacje ortopedyczne, operacje traumatologiczne, ułożenie pacjenta, opaska uciskowa',
    },
  ];

  private static removeHtml(stringWithHtml: string): string {
    return stringWithHtml.replace(/(<([^>]+)>)/gi, ' ');
  }

  // source: https://github.com/samvloeberghs/kwerri-oss/blob/master/projects/ngx-seo/src/lib/seo-social-share/seo-social-share.service.ts

  constructor(
    private readonly metaService: Meta,
    private readonly titleService: Title,
    private readonly configService: ConfigurationService,
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private state: TransferState,
    private router: Router
  ) {
    // TODO: transfer state
    // TODO: change to config servcie (?)
    this.configService.getConfiguration().subscribe((strapiConfig) => {
      if (!strapiConfig) {
        return;
      }

      this.defaultConfigTitle = strapiConfig.title;
      this.defaultConfigDesc = strapiConfig.description;
      if (strapiConfig.logo && strapiConfig.logo.url) {
        this.defaultConfigLogoUrl = new StrapiImageClass({
          url: strapiConfig.logo.url,
          provider: environment.provider,
        } as StrapiImage).getPublicImageUrl(
          this.serverUrl + '/strapi-proxy/' + this.tenantId
        );
      }
      if (strapiConfig.favicon && strapiConfig.favicon.url) {
        this.defaultConfigFaviconUrl = new StrapiImageClass({
          url: strapiConfig.favicon.url,
          provider: environment.provider,
        } as StrapiImage).getPublicImageUrl(
          this.serverUrl + '/strapi-proxy/' + this.tenantId
        );
      }
      if (typeof document !== 'undefined' && document !== null) {
        const favIcon: HTMLLinkElement = document.querySelector('#favIcon');
        favIcon.href = this.defaultConfigFaviconUrl;
      }

      this.setDefaultMetaData();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const ns: NavigationStart = event;
        if (ns.url && ns.url.length > 0) {
          this.createOrUpdateCanonical(ns.url);
          const meta = this.META_MAP.find((m) => m.addr === ns.url);
          if (meta) {
            this.createOrUpdateDescription(meta.desc);
            this.createOrUpdateKeywords(meta.keywords);
            this.createOrUpdateTitle(meta.title);
          }
        }
      }
    });
  }

  public setDefaultMetaData() {
    // TODO: favicon
    // TODO: site url from router
    this.setData(
      this.defaultConfigTitle ??
        'Portal dla pielęgniarek, pielęgniarzy, położnych, studentów',
      this.defaultConfigDesc ??
        'Portal Wydawnictwa Lekarskiego PZWL dla pielęgniarek, pielęgniarzy, położnych oraz studentów tych kierunków. Źródło wiedzy i praktycznych informacji.',
      this.defaultConfigLogoUrl ?? '',
      '',
      MetaOgType.Website,
      'nursing, pielęgniarka, położna, zawód pielęgniarki, zawód położnej, praca pielęgniarki, praca położnej, PZWL'
    );
  }

  public setData(
    title: string,
    description: string,
    image: string,
    url: string,
    type: MetaOgType,
    keywords: string
  ) {
    this.createOrUpdateTitle(title);
    this.createOrUpdateDescription(description);
    this.createOrUpdateImage(image);
    this.createOrUpdateUrl(url);
    this.createOrUpdateType(type);
    this.createOrUpdateKeywords(keywords);
  }

  public removeData() {
    this.createOrUpdateTitle('');
    this.createOrUpdateDescription('');
    this.createOrUpdateImage('');
    this.createOrUpdateUrl('');
    this.createOrUpdateType(MetaOgType.Website);
  }

  public createOrUpdateTitle(title: string = ''): void {
    title = MetaDataService.removeHtml(title);
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'twitter:title', content: title });
    this.metaService.updateTag({ name: 'twitter:image:alt', content: title });
    this.metaService.updateTag({ property: 'og:image:alt', content: title });
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ name: 'title', content: title });
    this.metaService.updateTag({ itemprop: 'name', content: title });
  }

  public createOrUpdateDescription(description: string): void {
    description = MetaDataService.removeHtml(description);
    this.metaService.updateTag({
      name: 'twitter:description',
      content: description,
    });
    this.metaService.updateTag({
      property: 'og:description',
      content: description,
    });
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({
      itemprop: 'description',
      content: description,
    });
  }

  public createOrUpdateImage(image: string): void {
    this.metaService.updateTag({ name: 'twitter:image', content: image });
    this.metaService.updateTag({ itemprop: 'image', content: image });
    this.metaService.updateTag({ property: 'og:image', content: image });
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary' });
  }

  public createOrUpdateUrl(url: string): void {
    this.metaService.updateTag({ property: 'og:url', content: url });
  }

  public createOrUpdateType(type: MetaOgType) {
    this.metaService.updateTag({ property: 'og:type', content: type });
  }

  public createOrUpdateKeywords(keywords: string) {
    this.metaService.updateTag({ name: 'keywords', content: keywords });
  }

  public createOrUpdateCanonical(url: string | undefined) {
    let oldlink: HTMLLinkElement = this.document.querySelector(
      'link[rel=canonical]'
    );
    if (oldlink) {
      oldlink.remove();
    }

    if (url) {
      let link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.document.head.appendChild(link);
      link.setAttribute('href', environment.serverUrl + url);
    }
  }

  public createOrUpdateProductRetailerPartNo(id: string) {
    this.metaService.updateTag({
      property: 'product:retailer_part_no',
      content: id,
    });
  }
}
