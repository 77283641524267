<div
  *ngIf="component && isBrowser()"
  class="categories-container"
  [ngClass]="{
    'one-category-left': component.categories.length % 3 === 1,
    'two-categories-left': component.categories.length % 3 === 2
  }"
>
  <ng-container *ngIf="categoriesLoaded">
    <app-top-article-category
      *ngFor="let category of articleCategories | keyvalue"
      [category]="category.key"
      [articles]="category.value"
      [showDates]="component.showDates"
    ></app-top-article-category>
  </ng-container>
</div>
