import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StrapiPartner } from '@nursing/pwn-cms-model/lib';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { TransferState, makeStateKey } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  private readonly tenantId = environment.tenantId;
  private timeout = false;

  constructor(private http: HttpClient, private state: TransferState) {}

  getPartnerList(): Observable<StrapiPartner[]> {
    type T = StrapiPartner[];
    const key = makeStateKey<T>('PartnerService_getPartnerList');
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.timeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.timeout = false;
        }, 2000);
        this.timeout = true;
      }
      return of(value);
    }
    return this.http.get<T>(`/strapi-proxy/${this.tenantId}/partner`).pipe(
      map((partnerList) => {
        partnerList.sort((a, b) => a.index - b.index);
        this.state.set<T>(key, partnerList);
        return partnerList;
      })
    );
  }
}
