import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Article,
  StrapiAdComponent,
  StrapiArticleHeaderComponent,
  StrapiComponentTypeEnum,
} from '@nursing/pwn-cms-model/lib';

@Component({
  selector: 'app-article-top-content',
  templateUrl: './article-top-content.component.html',
  styleUrls: ['./article-top-content.component.scss'],
})
export class ArticleTopContentComponent {
  @Input()
  components: (StrapiAdComponent | StrapiArticleHeaderComponent)[];

  @Input()
  article: Article;

  @Output() downloadPdfEvent = new EventEmitter();

  StrapiComponentTypeEnum = StrapiComponentTypeEnum;

  constructor() {}

  // downloadPdfRequest() {
  //   this.downloadPdfEvent.emit();
  // }
}
