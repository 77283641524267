<section class="large-footer-container-desktop" *ngIf="largeFooter">
  <header class="large-footer-title">{{ largeFooter.title }}</header>
  <div class="large-footer-items-container">
    <div class="large-footer-item" *ngFor="let item of largeFooter.components">
      <app-footer-dynamic-item
        *ngIf="item.__component === StrapiComponentTypeEnum.SubcategoryList"
        [item]="item"
      ></app-footer-dynamic-item>
      <app-footer-static-item
        *ngIf="item.__component === StrapiComponentTypeEnum.Paragraph"
        [item]="item"
      ></app-footer-static-item>
    </div>
  </div>
</section>

<section class="large-footer-container-mobile" *ngIf="largeFooter">
  <div class="large-footer-items-container">
    <div class="large-footer-row">
      <div
        class="large-footer-item"
        *ngFor="let item of largeFooter.components"
      >
        <app-footer-dynamic-item
          *ngIf="item.__component === StrapiComponentTypeEnum.SubcategoryList"
          [item]="item"
        ></app-footer-dynamic-item>
        <app-footer-static-item
          *ngIf="item.__component === StrapiComponentTypeEnum.Paragraph"
          [item]="item"
        ></app-footer-static-item>
      </div>
    </div>
  </div>
</section>
